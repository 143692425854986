<template>
  <v-container>
    <v-row>
      <v-col>
        <v-card-actions class="m-0">
          <v-spacer/>
          <v-badge overlap bordered left color="primary" :value="Object.keys(filterData.filter).map((key) => filterData.filter[key]).filter(v => Array.isArray(v) ? v.length :  v).length -1" :content="Object.keys(filterData.filter).map((key) => filterData.filter[key]).filter(v => Array.isArray(v) ? v.length :  v).length -1">
            <v-menu offset-y left :open-on-hover="false" :close-on-content-click="false" rounded="lg" v-model="dialogFilterQuery">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="primary"
                  icon
                  outlined
                  class="rounded-lg text-none"
                  v-bind="attrs" v-on="on"
                >
                  <v-icon>mdi-filter-variant</v-icon>
                </v-btn>
              </template>
              <v-card elevation="0" max-width="400">
                <v-card-actions>
                  <v-btn icon color="white">
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                  <v-spacer/>
                  <b>{{ $t('filterDashBoardTitle') }}</b>
                  <v-spacer/>
                  <v-btn icon color="#7E8EA6" @click="dialogFilterQuery = false">
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-card-actions>
                <v-divider class="mr-4 ml-4"/>
                <v-list>
                  <v-list-item v-for="key in Object.keys(filterData.filter).filter(key => Array.isArray(filterData.filter[key]) ? filterData.filter[key].length :  !!filterData.filter[key]).filter(key => key != 'clickDateRange')" :key="key" two-line>
                    <v-list-item-content>
                      <v-list-item-title><b>{{ $t(key) }}</b></v-list-item-title>
                      <v-list-item-subtitle class="description--text mt-0 pt-0" style="white-space: unset">
                        <div v-if="key == 'period'">
                          <label v-if="filterData.filter[key].length > 1">
                            {{ new Date(`${filterData.filter[key][0]}`).toLocaleDateString() }}
                            {{ $t('endAt') }}
                            {{ filterData.filter[key][1] ? new Date(`${filterData.filter[key][1]}`).toLocaleDateString() : $t('selectEndDate')}}
                          </label>
                          <label v-else>
                            {{ $t('allPeriod') }}
                          </label>
                        </div>
                        <div v-else-if="key == 'errorIn'">
                          {{ filterData.filter[key].map(v => $t('verificationOptions')[v]).join(', ') }}
                        </div>
                        <div v-else-if="Array.isArray(filterData.filter[key])">
                          {{ filterData.filter[key].map(v => $t(v)).join(', ') }}
                        </div>
                        <div v-else-if="['withClicks','withoutClicks'].includes(key)">
                          <label v-if="filterData.filter['clickDateRange'] && filterData.filter['clickDateRange'][0]">
                            {{ filterData.filter['clickDateRange'].map(v =>  new Date(v).toLocaleDateString()).join(` ${$t('endAt')} `) }}
                          </label>
                          <label v-else>{{ $t(filterData.filter[key]) }}</label>
                        </div>
                        <div v-else-if="typeof filterData.filter[key]  === 'string' || Array.isArray(filterData.filter[key] ) && filterData.filter[key].includes('T') && new Date(filterData.filter[key]) instanceof Date && !isNaN(new Date(filterData.filter[key]))">
                          {{ new Date(filterData.filter[key]).toLocaleDateString() }}
                        </div>
                        <div v-else-if="key == 'onlineAddressId'">
                          {{ dasboardData.onlineAddress ? dasboardData.onlineAddress.code : '' }}
                        </div>
                        <div v-else>
                          {{ $t(filterData.filter[key]) }}
                        </div>
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
                <v-card-subtitle class="description--text ma-4">
                  {{ $t('dashboardInfoText') }}
                </v-card-subtitle>
              </v-card>
            </v-menu>
          </v-badge>
        </v-card-actions>
      </v-col>
    </v-row>
    <v-card class="card-corner-radius" elevation="0" min-height="80vh">
      <v-card-title class="blue--text">
        <v-icon color="blue" class="mr-2">mdi-chart-bar</v-icon>
        {{$t('metrics')}}
        <v-spacer/>

        <v-menu offset-y left :open-on-hover="false" :close-on-content-click="false" rounded="lg" v-model="menuPeriod" v-if="periodSelect">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="primary"
              outlined
              dark
              class="rounded-lg text-none"
              v-bind="attrs" v-on="on"
            >
              <div v-if="periodSelect.id == `customPeriod`">
                {{ new Date(`${periodSelect.range[0]}T00:00`).toLocaleDateString() }}
                {{ $t('endAt') }}
                {{ periodSelect.range[1] ? new Date(`${periodSelect.range[1]}T00:00`).toLocaleDateString() : $t('selectEndDate')}}
              </div>
              <div v-else>
                {{ $t(periodSelect.id) }}
              </div>
              <v-icon>mdi-chevron-down</v-icon>
            </v-btn>
          </template>
          <v-card elevation="0">
            <v-card-actions>
              <v-btn icon color="white">
                <v-icon>mdi-close</v-icon>
              </v-btn>
              <v-spacer/>
              <b></b>
              <v-spacer/>
              <v-btn icon color="#7E8EA6" @click="menuPeriod = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-card-actions>
            <v-divider class="mr-4 ml-4"/>
            <v-list v-for="item in ['period']"
              :key="item.id"
              class="mb-0 pb-0 mt-0 pt-0"
              >
              <v-list-item-group
                v-model="periodSelect"
                color="primary"
                @change="filterData.filter.period = periodSelect.range, get(dasboardData.onlineAddress ? dasboardData.onlineAddress.id : null), savePeriodSelect()"
              >
                <v-list-item
                  v-for="op, index in getPeriod()" :key="index"
                  link
                  cli
                  :value="op"
                >
                  <v-list-item-title>
                    {{ $t(op.id) }}
                  </v-list-item-title>
                </v-list-item>
              </v-list-item-group>
              <v-divider/>
              <v-card elevation="0">
                <v-card-subtitle></v-card-subtitle>
                <v-card-text>
                  <v-date-picker
                    v-model="periodSelect.range"
                    range
                    :max="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)"
                    @change="v => {v.sort(), periodSelect = {id: 'customPeriod', range: v, days: 0}, filterData.filter.period = [new Date(`${v[0]}T00:00:00`).toISOString(), new Date(`${v[1]}T23:59:59`).toISOString()], get(dasboardData.onlineAddress ? dasboardData.onlineAddress.id : null), savePeriodSelect()}"
                  />
                </v-card-text>
              </v-card>
            </v-list>
          </v-card>
        </v-menu>

      </v-card-title>
      <v-card-text v-if="screenData.auth.user.userHasSubscriptionPlans[0]">
        <v-row v-if="!screenData.auth.user.userHasSubscriptionPlans.filter(v => v.subscriptionPlan.name.includes('URL_SHORTNER')).filter(v => ['URL_SHORTNER_BASIC', 'URL_SHORTNER_PRO', 'URL_SHORTNER_BUSINESS'].includes(v.subscriptionPlan.name))[0]">
          <v-col cols="12" >
            <v-card elevation="0" outlined class="rounded-lg">
              <v-card-title class="primary--text">{{ $t('accessCount') }}</v-card-title>
              <v-card-subtitle>{{ $t('accessCountBlockMessage') }}</v-card-subtitle>
              <v-card-actions>
                <v-spacer/>
                <IconInfoSubscriblePlan :screenData="screenData" :planName="$t('plan_BASIC')" :planData="planData"/>
                <v-spacer/>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
        <v-row v-else>
          <v-col cols="12">
              <v-card elevation="0" outlined class="rounded-lg">
                <v-skeleton-loader type="avatar" v-if="loading" class="pa-4"/>
                <div v-else>
                  <v-card-actions>
                    <b>{{ $t('views') }}</b>
                    <v-spacer/>
                      <v-tooltip left max-width="400" color="black">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            color="primary"
                            dark
                            v-bind="attrs"
                            v-on="on"
                            text
                            :loading="loadingPrevius"
                          >
                          {{ getPercent(dasboardData, dasboardDataPrevius) }}
                          </v-btn>
                        </template>
                        <v-card-subtitle>
                          <b>{{ $t('viewsComparePreviusPeriod') }}</b>
                        </v-card-subtitle>
                      </v-tooltip>
                  </v-card-actions>
                  <v-card-title class="ma-0 pa-0">
                    <v-spacer/>
                    <b class="mr-2 primary--text">{{dasboardData.clicks.toLocaleString()}}</b>
                    <v-spacer/>
                  </v-card-title>
                  <v-card-title class="ma-0 pa-0">
                    <v-spacer/>
                    <small class="blue--text">{{dasboardData.uniqueViews.toLocaleString()}} {{ $t('unique_views') }}</small>
                    <v-spacer/>
                  </v-card-title>
                  <v-card-subtitle v-if="!dasboardData.onlineAddress" class="description--text mt-0 pt-0">{{ $t('countAllLinks') }}</v-card-subtitle>
                  <v-card-subtitle v-else class="description--text mt-0 pt-0">
                    {{host}}/<b>{{dasboardData.onlineAddress.code}}</b>
                  </v-card-subtitle>
                </div>
              </v-card>
          </v-col>
        </v-row>

        <v-row v-if="!screenData.auth.user.userHasSubscriptionPlans.filter(v => v.subscriptionPlan.name.includes('URL_SHORTNER')).filter(v => ['URL_SHORTNER_PRO', 'URL_SHORTNER_BUSINESS'].includes(v.subscriptionPlan.name))[0]">
          <v-col cols="12" >
            <v-card elevation="0" outlined class="rounded-lg">
              <v-card-title class="primary--text">{{ $t('REPORT_COMPLETE') }}</v-card-title>
              <v-card-subtitle>{{ $t('desc_REPORT_COMPLETE').description }}</v-card-subtitle>
              <v-card-actions>
                <v-spacer/>
                <IconInfoSubscriblePlan :screenData="screenData" :planName="$t('plan_PRO')" :planData="planData"/>
                <v-spacer/>
              </v-card-actions>
            </v-card>
          </v-col>
          <v-col cols="12">
            <carousel :autoplay="true" :perPage="1" :minSwipeDistance="5">
              <slide v-for="(img) in ['/images/010.png']" :key="img" >
                <v-card height="40vh" elevation="0">
                  <v-img :src="img" width="100%" contain/>
                </v-card>
              </slide>
            </carousel>
          </v-col>
        </v-row>
        <div v-else>
          <v-row>
            <v-col cols="12">
              <v-fade-transition leave-absolute hide-on-leave>
                <v-card class="rounded-lg" elevation="0" outlined @click="dialogViewsPerDate = true" :disabled="loadingViewsPerDate">
                  <v-skeleton-loader type="paragraph" v-if="loadingViewsPerDate" class="pa-4"/>
                  <div v-else>
                    <v-card-actions class="pa-4">
                      <b>{{ $t('viewsPerDate') }} ({{ $t(dateAgregation) }})</b>
                      <v-spacer/>
                      <v-tooltip left max-width="400" color="black">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            color="primary"
                            dark
                            v-bind="attrs"
                            v-on="on"
                            icon
                          >
                            <v-icon>mdi-information-outline</v-icon>
                          </v-btn>
                        </template>
                        <v-card-subtitle>
                          <b>{{ $t('viewsPerDateDisclaimer') }}</b>
                        </v-card-subtitle>
                      </v-tooltip>
                    </v-card-actions>
                    <v-card-text v-if="chartByDate[1]">
                      <GChart
                        width="100%"
                        type="LineChart"
                        :data="loadingViewsPerDate ? [['date', 'views', 'unique_views'][new Date().getFullYear(),  0, 0]] : dateAgregation == 'DAY' ? (chartByDate).map((v, index) => index > 0 ?[new Date(`${v[0]}T00:00:00`).toLocaleDateString(), v[1], v[2]]: [$t(v[0]), $t(v[1]), $t(v[2])]) 
                              : dateAgregation == 'MONTH' ? (chartByDate).map((v, index) => index > 0 ?[new Date(v[0].split('-')[0], v[0].split('-')[1] -1).toLocaleDateString(getLocale(), { year: 'numeric', month: 'long' }), v[1], v[2]]: [$t(v[0]), $t(v[1]), $t(v[2])]) : (chartByDate).map((v, index) => index > 0 ?[`${v[0]}`, v[1], v[2]]: [$t(v[0]), $t(v[1]), $t(v[2])])"
                        :options="{
                          legend: { position: 'none' },
                          width: '100%',
                          series: {
                            0: { color: '#BBDEFB', pointSize: 5}, // Defina a cor da série aqui
                            1: { color: '#0079F1', pointSize: 3}, // Defina a cor da série aqui
                          },
                          hAxis: {
                            gridlines: { color: 'none' },
                            textStyle: { color: '#7E8EA6' }, // Cor dos textos do eixo horizontal
                            format:''
                          },
                          vAxis: {
                            gridlines: { color: 'none' },
                            textStyle: { color: '#7E8EA6' }, // Cor dos textos do eixo vertical
                            format:''
                          },
                          chartArea: {
                            width: '80%', // Ajuste conforme necessário
                            backgroundColor: 'transparent' // Define a cor de fundo do gráfico
                          },
                          backgroundColor: 'transparent', // Define a cor de fundo da área do gráfico,
                          tooltip: {
                            trigger: 'both',
                            textStyle: {
                              color: '#7E8EA6'
                            }
                          }
                        }"
                      />
                    </v-card-text>
                  </div>
                  <v-bottom-sheet v-model="dialogViewsPerDate" >
                    <v-card elevation="0">
                      <v-card-actions>
                        <v-btn icon color="white">
                          <v-icon>mdi-close</v-icon>
                        </v-btn>
                        <v-spacer/>
                        <b>{{ $t('viewsPerDate') }}</b>
                        <v-spacer/>
                        <v-btn icon color="#7E8EA6" @click="dialogViewsPerDate = false">
                          <v-icon>mdi-close</v-icon>
                        </v-btn>
                      </v-card-actions>
                      <v-divider class="mr-4 ml-4"/>
                      <v-card-actions>
                        <v-spacer v-for="i in 5" :key="i"/>
                        <v-select
                          v-model="dateAgregation"
                          :items="['DAY', 'MONTH', 'YEAR']"
                          :label="$t('period')"
                          dense
                          outlined
                          rounded
                          @change="changeTimeUnit()"
                        >
                          <template v-slot:selection="{item}">
                            {{ $t(item) }}
                          </template >
                          <template v-slot:item="{item}">
                            {{ $t(item) }}
                          </template>                          
                        </v-select>
                      </v-card-actions>
                      <v-skeleton-loader type="card-heading, article, actions" v-if="loadingViewsPerDate"/>
                      <v-card-text v-else>
                        <GChart
                          width="100%"
                          type="LineChart"
                          :data="loadingViewsPerDate ? [['date', 'views', 'unique_views'][new Date().getFullYear(),  0, 0]] : dateAgregation == 'DAY' ? fillMissingDays(chartByDate).map((v, index) => index > 0 ?[new Date(`${v[0]}T00:00:00`).toLocaleDateString(), v[1], v[2]]: [$t(v[0]), $t(v[1]), $t(v[2])]) 
                            : dateAgregation == 'MONTH' ? fillMissingMonths(chartByDate).map((v, index) => index > 0 ?[new Date(v[0].split('-')[0], v[0].split('-')[1] -1).toLocaleDateString(getLocale(), { year: 'numeric', month: 'long' }), v[1], v[2]]: [$t(v[0]), $t(v[1]), $t(v[2])]) : fillMissingYears(chartByDate).map((v, index) => index > 0 ?[`${v[0]}`, v[1], v[2]]: [$t(v[0]), $t(v[1]), $t(v[2])])"
                          :options="{
                            legend: { position: 'none' },
                            width: '100%',
                            series: {
                              0: { color: '#BBDEFB', pointSize: 5}, // Defina a cor da série aqui
                              1: { color: '#0079F1', pointSize: 3}, // Defina a cor da série aqui
                            },
                            hAxis: {
                              gridlines: { color: 'none' },
                              slantedTextAngle: 45,
                              textStyle: { color: '#7E8EA6' }, // Cor dos textos do eixo horizontal
                              format:''
                            },
                            vAxis: {
                              gridlines: { color: 'none' },
                              textStyle: { color: '#7E8EA6' }, // Cor dos textos do eixo vertical
                              format:''
                            },
                            chartArea: {
                              width: '90%', // Ajuste conforme necessário
                            },
                            tooltip: {
                              trigger: 'both',
                              textStyle: {
                                color: '#7E8EA6'
                              }
                            }
                          }"
                        />
                      </v-card-text>
                    </v-card>
                  </v-bottom-sheet>
                </v-card>
              </v-fade-transition>
            </v-col>
            <v-col cols="12">
              <v-fade-transition leave-absolute hide-on-leave>
                <v-card class="rounded-lg" elevation="0" outlined @click="dialogViewsPerRegion = true" :disabled="loadingViewsPerRegion">
                  <v-skeleton-loader type="paragraph" v-if="loadingViewsPerRegion" class="pa-4"/>
                  <div v-else>
                    <v-card-actions class="pa-4">
                      <b>{{ $t('viewsPerRegion') }}</b>
                      <v-spacer/>
                      <v-tooltip left max-width="400" color="black">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            color="primary"
                            dark
                            v-bind="attrs"
                            v-on="on"
                            icon
                          >
                            <v-icon>mdi-information-outline</v-icon>
                          </v-btn>
                        </template>
                        <v-card-subtitle>
                          <b>{{ $t('viewsPerRegionDisclaimer') }}</b>
                        </v-card-subtitle>
                      </v-tooltip>
                    </v-card-actions>
                    <v-card-text v-if="chartByRegion[1]">
                      <GChart 
                        type="GeoChart"
                        :data="chartByRegion.map((v, index) => index ? v : [v[0], $t(v[1])])"
                        :settings="{
                          packages: ['geochart'],
                        }"
                        :options="{
                          displayMode: 'regions',
                          showTooltip: true,
                          showInfoWindow: true,
                          backgroundColor: 'transparent', // Cor de fundo do mapa
                          datalessRegionColor: 'transparent', // Cor para regiões sem dados
                          defaultColor: 'transparent', // Cor padrão para regiões sem dados específicos
                          colors: materialColorsMap,
                        }"
                      />
                    </v-card-text>
                    <v-bottom-sheet v-model="dialogViewsPerRegion" >
                      <v-card elevation="0" min-height="400" max-height="80vh" class="overflow-x-auto">
                        <v-card-actions>
                          <v-btn icon color="white">
                            <v-icon>mdi-close</v-icon>
                          </v-btn>
                          <v-spacer/>
                          <b>{{ $t('viewsPerRegion') }}</b>
                          <v-spacer/>
                          <v-btn icon color="#7E8EA6" @click="dialogViewsPerRegion = false">
                            <v-icon>mdi-close</v-icon>
                          </v-btn>
                        </v-card-actions>
                        <v-divider class="mr-4 ml-4"/>
                        <v-skeleton-loader type="card-heading, article, actions" v-if="loadingViewsPerRegion"/>
                        <v-card-text v-else>
                          <v-card elevation="0" class="mt-4">
                            <GChart 
                              class="pointer"
                              ref="geoChart"
                              type="GeoChart"
                              :data="(chartByRegion).map((v, index) => index ? v : [v[0], $t(v[1])])"
                              :settings="{
                                packages: ['map'],
                                // mapsApiKey: 'AIzaSyBiRMoA_SSUNeh-yXwUsjSVlbp3t0dVaoE'
                              }"
                              :options="{
                                backgroundColor: 'transparent', // Cor de fundo do mapa
                                datalessRegionColor: 'transparent', // Cor para regiões sem dados
                                defaultColor: 'transparent', // Cor padrão para regiões sem dados específicos
                                colors: materialColorsMap,
                                height: 400,
                              }"
                              @ready="onGeoChartReady"
                              :events="chartEvents"
                            
                            />
                          </v-card>
                            
                          
                          <v-bottom-sheet v-model="regionDetail" color="transparent">
                            <v-card outlined min-height="400" max-height="80vh" class="overflow-x-auto">
                              <v-card-actions>
                                <v-btn icon color="white">
                                  <v-icon>mdi-close</v-icon>
                                </v-btn>
                                <v-spacer/>
                                <b>{{ $t(countrySelect) }} ({{ (`${chartByRegion.filter(v => v[0] == countrySelect).map(v => v[1])[0]}`).toLocaleString() }} {{ $t('views') }})</b>
                                <v-spacer/>
                                <v-btn icon color="#7E8EA6" @click="regionDetail = false">
                                  <v-icon>mdi-close</v-icon>
                                </v-btn>
                              </v-card-actions>
                              <v-divider class="mr-4 ml-4"/>
                              <v-card-actions>
                                <v-spacer/>
                                <label>
                                  <v-card elevation="0" color="blue" dark class="ma-1 pl-1 pr-1">
                                    <v-icon small>mdi-chart-line-variant</v-icon>
                                    {{ $t('geoChartLineInfo') }}
                                  </v-card>
                                </label>
                              </v-card-actions>
                              <v-card-actions>
                                <v-spacer/>
                                <label>
                                  <v-card elevation="0" color="primary" dark class="ma-1 pl-1 pr-1">
                                    <v-icon small>mdi-poll</v-icon>
                                    {{ $t('geoChartBarInfo') }}
                                  </v-card>
                                </label>
                              </v-card-actions>
                              <v-skeleton-loader type="card-heading, article, actions" v-if="regionByCountryLoading"/>
                              <div v-else v-for="region in regions.filter(v => v.id == countrySelect)" :key="region.id" class="mt-2 mb-2" :id="region.id">
                                <v-skeleton-loader type="card-heading, article, actions" v-if="region.loading"/>
                                <v-card-text v-else>
                                  <GChart 
                                    type="ComboChart"
                                    :data="region.chart"
                                    :settings="{
                                      packages: ['corechart', 'bar'],
                                    }"
                                    :options="{
                                      seriesType: 'bars',
                                      isStacked: true,
                                      legend: { position: 'none' },
                                      width: '100%',
                                      series: {
                                        0: { color: 'blue', type: 'line', pointSize: 5  }, // Defina a cor da série aqui
                                      },
                                      hAxis: {
                                        gridlines: { color: 'none' },
                                        textStyle: { color: '#7E8EA6' }, // Cor dos textos do eixo horizontal
                                        format:''
                                      },
                                      vAxis: {
                                        gridlines: { color: 'none' },
                                        textStyle: { color: '#7E8EA6' }, // Cor dos textos do eixo vertical
                                        format:''
                                      },
                                      chartArea: {
                                        width: '80%', // Ajuste conforme necessário
                                        backgroundColor: 'transparent' // Define a cor de fundo do gráfico
                                      },
                                      backgroundColor: 'transparent', // Define a cor de fundo da área do gráfico,
                                      tooltip: {
                                        trigger: 'both',
                                        textStyle: {
                                          color: '#7E8EA6'
                                        }
                                      },
                                      width: '100%',
                                      colors: materialColors
                                      // height: region.chart[0].length > 20 ? 0.6 * region.chart[0].length : 200,
                                    }"
                                  />
                                </v-card-text>
                                <v-card-subtitle class="description--text ma-4">
                                  {{ $t('undefinedGeoLocation') }}
                                </v-card-subtitle>
                              </div>
                            </v-card>
                          </v-bottom-sheet>
                        </v-card-text>
                      </v-card>
                    </v-bottom-sheet>
                  </div>
                </v-card>
              </v-fade-transition>
            </v-col>
            <v-col cols="12">
              <v-card class="rounded-lg" elevation="0" outlined>
                <v-skeleton-loader type="paragraph" v-if="loadingViewsPerScreen" class="pa-4"/>
                <div v-else>
                  <v-card-actions class="pa-4">
                    <b>{{ $t('viewsPerScreenSize') }}</b>
                    <v-spacer/>
                    <v-tooltip left max-width="400" color="black">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          color="primary"
                          dark
                          v-bind="attrs"
                          v-on="on"
                          icon
                        >
                          <v-icon>mdi-information-outline</v-icon>
                        </v-btn>
                      </template>
                      <v-card-subtitle>
                        <b>{{ $t('viewsPerScreenSizeDisclaimer') }}</b>
                      </v-card-subtitle>
                    </v-tooltip>
                  </v-card-actions>
                  <v-card-text v-if="chartByScreen[1]">
                    <GChart
                      width="100%"
                      type="PieChart"
                      :data="chartByScreen.map((v, index) => index > 0 ?[$t('displays').filter(d => d.id == v[0])[0] ? $t('displays').filter(d => d.id == v[0])[0].description : $t('undefined'), v[1]]: [$t(v[0]), $t(v[1])])"
                      :options="{
                        pieHole: 0.4,
                        // legend: { position: 'none' },
                        width: '100%',
                        series: {
                          // 0: { color: '#0079F1' }, // Defina a cor da série aqui
                          // 1: { color: '#6A1B9A' }, // Defina a cor da série aqui
                          // 2: { color: '#1565C0' }, // Defina a cor da série aqui
                          // 3: { color: '#2E7D32' }, // Defina a cor da série aqui
                          // 4: { color: '#FF8F00' }, // Defina a cor da série aqui
                        },
                        hAxis: {
                          gridlines: { color: 'none' },
                          textStyle: { color: '#7E8EA6' }, // Cor dos textos do eixo horizontal
                          format:''
                        },
                        vAxis: {
                          gridlines: { color: 'none' },
                          textStyle: { color: '#7E8EA6' }, // Cor dos textos do eixo vertical
                          format:''
                        },
                        chartArea: {
                          width: '100%', // Ajuste conforme necessário
                          backgroundColor: 'transparent' // Define a cor de fundo do gráfico
                        },
                        backgroundColor: 'transparent', // Define a cor de fundo da área do gráfico,
                        tooltip: {
                          trigger: 'both',
                          textStyle: {
                            color: '#7E8EA6'
                          }
                        },
                        colors: materialColors,
                      }"
                    />
                  </v-card-text>
                </div>
              </v-card>
            </v-col>
            <v-col cols="12">
              <v-card class="rounded-lg" elevation="0" outlined>
                <v-skeleton-loader type="paragraph" v-if="loadingViewsPerShareOn" class="pa-4"/>
                <div v-else>
                  <v-card-actions class="pa-4">
                    <b>{{ $t('sharedOnViews') }}</b>
                    <v-spacer/>
                    <v-tooltip left max-width="400" color="black">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          color="primary"
                          dark
                          v-bind="attrs"
                          v-on="on"
                          icon
                        >
                          <v-icon>mdi-information-outline</v-icon>
                        </v-btn>
                      </template>
                      <div>
                        <v-card-subtitle>
                          <b>{{ $t('sharedOnViewsDisclaimer') }}</b>
                        </v-card-subtitle>
                        <v-card-text v-for="item in ['/images/share-on/001.png', '/images/share-on/002.png', '/images/share-on/003.png']" :key="item">
                          <v-img :src="item" class="rounded-lg"/>
                        </v-card-text>
                      </div>
                    </v-tooltip>
                  </v-card-actions>
                  <v-card-text v-for="op in chartByShareOn.slice(1)" :key="op[0]" class="mt-2 mb-2 pb-0 pt-0">
                    <v-card elevation="0" v-if="$t('shareOptions').filter(v => v.value == op[0])[0]" :color="$t('shareOptions').filter(v => v.value == op[0])[0].color" dark>
                      <v-card-actions >
                        <v-icon>{{ $t('shareOptions').filter(v => v.value == op[0])[0].icon }}</v-icon>
                        <label class="ml-4">{{ $t('shareOptions').filter(v => v.value == op[0])[0].description }}</label>
                        <v-spacer/>
                        <b>{{ op[1] }}</b>
                        <v-chip class="ml-2" outlined>
                          {{ (op[1] / chartByShareOn.slice(1).map(v => v[1]).reduce((accumulator, currentValue) => accumulator + currentValue, 0) * 100).toLocaleString()}}%
                        </v-chip>
                      </v-card-actions>
                    </v-card>
                    <v-card v-else-if="op[1]" elevation="0" outlined>
                      <v-card-actions>
                        <v-icon>mdi-share</v-icon>
                        <label class="ml-4 description--text">{{ $t(op[0]) }}</label>
                        <v-spacer/>
                        <b>{{ op[1] }}</b>
                        <v-chip class="ml-2" outlined>
                          {{ (op[1] / chartByShareOn.slice(1).map(v => v[1]).reduce((accumulator, currentValue) => accumulator + currentValue, 0) * 100).toLocaleString()}}%
                        </v-chip>
                      </v-card-actions>
                    </v-card>
                  </v-card-text>
                </div>
              </v-card>
            </v-col>

            <v-col cols="12">
              <v-card class="rounded-lg" elevation="0" outlined>
                <v-skeleton-loader type="paragraph" v-if="loadingViewsPerScreen" class="pa-4"/>
                <div v-else>
                  <v-card-subtitle><b>{{ $t('platformDescription') }}</b></v-card-subtitle>
                  <v-card-text v-if="chartByPlatform[1]">
                    <GChart
                      width="100%"
                      type="BarChart"
                      :data="chartByPlatform.map((v, index) => index > 0 ?[$t(v[0]), v[1]]: [$t(v[0]), $t(v[1])])"
                      :options="{
                        legend: { position: 'none' },
                        width: '100%',
                        hAxis: {
                          gridlines: { color: 'none' },
                          textStyle: { color: '#7E8EA6' }, // Cor dos textos do eixo horizontal
                          format:''
                        },
                        vAxis: {
                          gridlines: { color: 'none' },
                          textStyle: { color: '#7E8EA6' }, // Cor dos textos do eixo vertical
                          format:''
                        },
                        chartArea: {
                          width: '80%', // Ajuste conforme necessário
                          backgroundColor: 'transparent' // Define a cor de fundo do gráfico
                        },
                        backgroundColor: 'transparent', // Define a cor de fundo da área do gráfico,
                        tooltip: {
                          trigger: 'both',
                          textStyle: {
                            color: '#7E8EA6'
                          }
                        },
                        colors: materialColors,
                      }"
                    />
                  </v-card-text>
                </div>
              </v-card>
            </v-col>

            <v-col cols="12">
              <v-card class="rounded-lg pb-2" elevation="0" outlined>
                <v-card-actions class="pa-4">
                  <b>{{ $t('viewsPerDomains') }}</b>
                  <v-spacer/>
                  <v-btn icon color="primary" @click="getByDomain(pageDomain -1)" :disabled="pageDomain <= 0">
                    <v-icon>mdi-chevron-left-circle</v-icon>
                  </v-btn>
                  <v-btn icon color="primary" @click="getByDomain(pageDomain + 1)" :disabled="!chartByDomain.slice(1)[0] || chartByDomain.slice(1).length < 10">
                    <v-icon>mdi-chevron-right-circle</v-icon>
                  </v-btn>
                </v-card-actions>
                <v-skeleton-loader type="paragraph" v-if="loadingChartByDomain" class="pa-4"/>
                <v-card-text v-else v-for="op in chartByDomain.slice(1)" :key="op[0]" class="mt-1 mb-1 pb-0 pt-0">
                  <v-card elevation="0" outlined>
                    <v-list class="pa-0 ma-0">
                      <v-list-item>
                        <v-list-item-content class="pa-0 ma-0">
                          <v-list-item-title>
                            <b v-if="!!op[0]">{{ op[0] }}</b>
                            <b v-else><i>{{ $t('withoutDomain') }}</i></b>
                          </v-list-item-title>
                          <v-list-item-subtitle v-if="op[2]">
                            ({{ op[1].toLocaleString() }} {{ $t('links') }}) / ({{ op[2].toLocaleString() }} {{ $t('views') }})
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-card>
                </v-card-text>
              </v-card>
            </v-col>

          </v-row>
        </div>
      </v-card-text>
      <v-card-actions></v-card-actions>
    </v-card>
    <v-dialog v-model="planData.dialogPlansAndPrices" width="800">
      <v-card>
        <v-card-actions>
          <v-spacer/>
          <v-btn @click="planData.dialogPlansAndPrices = false" icon><v-icon color="red">mdi-close</v-icon></v-btn>
        </v-card-actions>
        <v-card-text>
          <SubscriptionAndPlans :screenData="screenData"/>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import { apiV1ClickDashboard } from "../../../service/resources/api-v1-click-dashboard";
import SubscriptionAndPlans from "./SubscriptionAndPlans.vue";
import IconInfoSubscriblePlan from "./IconInfoSubscriblePlan.vue";

  export default {
    name: 'UrlShortenerDashboard',
    props: ['screenData'],
    components: {
    SubscriptionAndPlans,
    IconInfoSubscriblePlan
},
    data: () => ({
      dialogFilterQuery: false,
      filterData: {
        filter: {
          text: null,
          domain: null,
          errorIn: null,
          typeIn: null,
          dateCreateIni: null,
          dateCreateEnd: null,
          withClicks: null,
          withoutClicks: null,
          clickDateRange: [],
          onlineAddressId: null,
          period: null,
        }
      },
      periodSelect: null,
      dialogViewsPerRegion: false,
      loadingViewsPerRegion: false,
      dateAgregation: 'DAY',
      dialogViewsPerDate: false,
      chartRegion: null,
      planData: {
        dialogPlansAndPrices: false,
      },
      dasboardData: {},
      dasboardDataPrevius: {},
      dialogFilter: false,
      activePickerStart: null,
      activePickerEnd: null,
      menuStartAt: false,
      menuEndAt: false,
      startAt: null,
      endAt: null,
      regionDetail: false,
      clicks: 0,
      interval: {},
      loading: true,
      menuPeriod: false,
      chartOptions: {
        chart: {
          title: 'Company Performance',
          subtitle: 'Sales, Expenses, and Profit: 2014-2017',
        }
      },
      host: null,
      chartByDate: [
        ['date', 'views'],
        [new Date().getFullYear(),  0]
      ],
      chartByRegion: [
        ['country', 'views'],
        ['',  0]
      ],
      chartByDomain: [
        ['domain', 'views'],
        ['',  0]
      ],
      loadingChartByDomain: false,
      chartByRegionState: [['country', 'views'],['',  0]],
      loadingViewsPerDate: true,
      chartEvents: {
        'select': () => {
        }
      },
      regionByCountryLoading: false,
      chartByRegionCountry: [
        ['city', 'views'],
        ['',  0]
      ],
      pageDomain: 0,
      regions: [{id: 'viewsPerRegion', chart: [['city', 'views'],['',  0]], loading: true},],
      chartByScren: [
        ['city', 'views'],
      ],
      loadingViewsPerScreen: true,
      chartByPlatform: [
        ['city', 'views'],
        ['',  0]
      ],
      loadingViewsPerPlatform: true,
      chartByShareOn: [
        ['city', 'views'],
      ],
      loadingViewsPerShareOn: true,
      materialColors: [
        '#0D47A1', // Darkest blue
        '#1565C0', // Dark blue
        '#1976D2', // Darker blue
        '#1E88E5', // Slightly darker blue
        '#2196F3', // Standard blue
        '#42A5F5', // Medium blue
        '#64B5F6', // Light-medium blue
        '#90CAF9', // Light blue
        '#BBDEFB', // Lighter blue
        '#E3F2FD'  // Lightest blue
      ], 
      materialColorsMap: [],
      countrySelect: null,
      loadingPrevius: false
    }),
    watch: {
      '$route.query': function(query) {
        //TO DO atualizar 
        this.get(query.onlineAddressId)
      },
      '$route.query.onlineAddressId': function(onlineAddressId) {
        this.get(onlineAddressId)
      },
      'dialogViewsPerRegion': function(dialog) {
        if(dialog) {
          this.getByRegion(this.dasboardData.onlineAddress ? this.dasboardData.onlineAddress.id : null)
        }
      },
      'menuPeriod' : function() {
        if(this.periodSelect.id == 'customPeriod') {
          if(!this.periodSelect.range[0] || !this.periodSelect.range[1]) {
            if(!this.periodSelect.range[0])
              this.$commons.updateSnackbar({show: true, color: 'warning', text: this.$t('setStartPeriod')})
            if(!this.periodSelect.range[1])
              this.$commons.updateSnackbar({show: true, color: 'warning', text: this.$t('setEndPeriod')})
            setTimeout(() => {
              this.menuPeriod = true
            }, 100);
          }
        }
      }
    },
    mounted: function() {
      this.materialColorsMap = [...this.materialColors]
      this.materialColorsMap.reverse()
      if(localStorage.getItem('dashBoardDateTimeUnit'))
        this.dateAgregation = localStorage.getItem('dashBoardDateTimeUnit')
      this.host = `${window.location.protocol}//${window.location.host.replace('www.', '')}`;
      

      if(localStorage.getItem('periodSelect')) {
        this.periodSelect = JSON.parse(localStorage.getItem('periodSelect'))
      } else {
        this.periodSelect = this.getPeriod()[2]
      }
      if(this.periodSelect.id == 'customPeriod') {
        this.filterData.filter.period = [
              `${new Date(`${this.periodSelect.range[0]}T00:00:00`).toISOString()}`, 
              `${new Date(`${this.periodSelect.range[1]}T23:59:59`).toISOString()}`
            ]
      } else {
        this.filterData.filter.period = this.periodSelect.range
      }
      this.get(this.$route.query.onlineAddressId)
    },
    computed: {

    },
    methods: {
      getRandomColor() {
        const randomIndex = Math.floor(Math.random() * this.materialColors.length);
        return this.materialColors[randomIndex];
      },
      isFacebook: function(name) {
        return name.toLowerCase().includes('facebook')
      },
      isYoutube: function(name) {
        return name.toLowerCase().includes('youtube')
      },
      isInstagram: function(name) {
        return name.toLowerCase().includes('instagram')
      },
      isLinkedin: function(name) {
        return name.toLowerCase().includes('linkedin')
      },
      isWhatsapp: function(name) {
        return name.toLowerCase().includes('whatsapp')
      },
      isEmail: function(name) {
        return name.toLowerCase().includes('@')
      },
      isEducation: function(name) {
        return name.toLowerCase().includes('lattes') || name.toLowerCase().includes('buscatextual.cnpq')
      },
      isTwitter: function(name) {
        return name.toLowerCase().includes('twitter')
      },
      isYoutubePreview: function(name) {
        return name.toLowerCase().includes('watch')
      },
      get: function(id) {
        this.filterData.filter.period.sort()
        this.filterData.filter = {
          text: this.$route.query.text,
          domain: this.$route.query.domain,
          errorIn: this.$route.query.errorIn ? Array.isArray(this.$route.query.errorIn) ? this.$route.query.errorIn : [this.$route.query.errorIn] : null,
          typeIn: this.$route.query.typeIn ? Array.isArray(this.$route.query.typeIn) ? this.$route.query.typeIn : [this.$route.query.typeIn] : null,
          dateCreateIni: this.$route.query.dateCreateIni ? `${new Date(`${this.$route.query.dateCreateIni}T00:00:00`).toISOString()}`  : null, 
          dateCreateEnd: this.$route.query.dateCreateEnd ? `${new Date(`${this.$route.query.dateCreateEnd}T23:59:59`).toISOString()}`  : null, 
          withClicks: this.$route.query.withClicks === "true",
          withoutClicks: this.$route.query.withoutClicks === "true",
          clickDateRange: this.$route.query.clickDateRange ? Array.isArray(this.$route.query.clickDateRange) ? this.$route.query.clickDateRange.map((v, index) => index ? `${new Date(`${v}T23:59:59`).toISOString()}` : `${new Date(`${v}T00:00:00`).toISOString()}`) : [] : null,
          onlineAddressId: id,
          period: this.filterData.filter.period,
          timeUnit: this.dateAgregation
        }

        this.requestNumber = `${crypto.randomUUID()}`
        let request = `${this.requestNumber}`

        let self = this
        self.loading = true
        self.clicks = 0
        apiV1ClickDashboard.get(this.filterData.filter).then(result => {
          if(request == this.requestNumber) {
            self.dasboardData = result.data
            self.loading = false
          }
        })

        if(this.filterData.filter.period && this.filterData.filter.period[0] && this.filterData.filter.period[1]) {
          let previusPeriod = this.getPreviousPeriod(this.filterData.filter.period)
          let copyFilter = JSON.parse(JSON.stringify(this.filterData.filter))
          copyFilter.period = previusPeriod
          this.loadingPrevius = true
          apiV1ClickDashboard.get(copyFilter).then(result => {
            if(request == this.requestNumber) {
              this.dasboardDataPrevius = result.data
              this.loadingPrevius = false
            }
          })
        } else {
          this.dasboardDataPrevius = {clicks: 0}
        }


        this.getByDate()
        this.getByPropertie('country', null, `chartByRegion`, `loadingViewsPerRegion`)
        // this.getByPropertie('state', null, `chartByRegionState`, `loadingViewsPerRegionState`)
        this.getByPropertie('breakpoint-name', null, `chartByScreen`, `loadingViewsPerScreen`)
        this.getByPropertie('platform', null, `chartByPlatform`, `loadingViewsPerPlatform`)
        this.getByPropertie('share-on', null, `chartByShareOn`, `loadingViewsPerShareOn`)
        this.getByDomain(0)

      },
      getByDate: function() {
        
        this.chartByDate = [],
        this.filterData.filter.timeUnit = this.dateAgregation
        if(this.screenData.auth.user.userHasSubscriptionPlans.filter(v => v.subscriptionPlan.name.includes('URL_SHORTNER')).filter(v => ['URL_SHORTNER_PRO', 'URL_SHORTNER_BUSINESS'].includes(v.subscriptionPlan.name))[0]) {
          this.requestNumberByDate = `${crypto.randomUUID()}`
          let requestNumberByDate = `${this.requestNumberByDate}`
          this.loadingViewsPerDate = true
          apiV1ClickDashboard.getByDate(this.filterData.filter).then(result => {
            if(requestNumberByDate == this.requestNumberByDate) {
              this.chartByDate = result.data.data
              this.loadingViewsPerDate = false
            }
          })
        }
      },
      getByPropertie: function(name, value, chart, loading) {
        this[chart] = []
        if(this.screenData.auth.user.userHasSubscriptionPlans.filter(v => v.subscriptionPlan.name.includes('URL_SHORTNER')).filter(v => ['URL_SHORTNER_PRO', 'URL_SHORTNER_BUSINESS'].includes(v.subscriptionPlan.name))[0]) {
          this[loading] = true
          let filter = JSON.parse(JSON.stringify(this.filterData.filter))
          filter.name = name
          filter.value = value

          this[name] = `${crypto.randomUUID()}`
          let req = `${this[name]}`

          apiV1ClickDashboard.getByPropertie(filter).then(result => {
            if(req === this[name]) {
              this[chart] = result.data.data
              this[loading] = false
            }
          })
        }
      },
      getByRegion: function() {
        if(this.screenData.auth.user.userHasSubscriptionPlans.filter(v => v.subscriptionPlan.name.includes('URL_SHORTNER')).filter(v => ['URL_SHORTNER_PRO', 'URL_SHORTNER_BUSINESS'].includes(v.subscriptionPlan.name))[0]) {
          this.regionByCountryLoading = true

          this.regionReq= `${crypto.randomUUID()}`
          let req = `${this.regionReq}`

          apiV1ClickDashboard.getByRegion(this.filterData.filter).then(result => {
            if(req === this.regionReq) {
              this.chartByRegionCountry = result.data.data
              
              
              
              this.regions = []
              this.chartByRegion.slice(1).forEach(country => this.regions.push({id: country[0], chart: null, loading: true}))
              this.regions.forEach(v => {
                  setTimeout(() => {
                      v.chart = this.formatRegion(v.id)
                      v.loading = false
                    }, 100);
                  })
                  
          
                
                this.regionByCountryLoading = false
            }
          })
        }
      },
      getByDomain: function(page) {
        this.pageDomain = page
        this.chartByDomain = []
        if(this.screenData.auth.user.userHasSubscriptionPlans.filter(v => v.subscriptionPlan.name.includes('URL_SHORTNER')).filter(v => ['URL_SHORTNER_PRO', 'URL_SHORTNER_BUSINESS'].includes(v.subscriptionPlan.name))[0]) {
          this.loadingChartByDomain = true
          let filter = JSON.parse(JSON.stringify(this.filterData.filter))
          filter.page = page
          filter.size = 10

          this.chart_By_Domain_request = `${crypto.randomUUID()}`
          let req = `${this.chart_By_Domain_request}`

          apiV1ClickDashboard.getByDomain(filter).then(result => {
            if(req === this.chart_By_Domain_request) {
              this.chartByDomain = result.data.data
              this.loadingChartByDomain = false
            }
          })
        }
      },
      getPreviousPeriod: function(period) {
          const startDate = new Date(period[0]);
          const endDate = new Date(period[1]);

          // Calcular a diferença de dias entre as duas datas
          const diffTime = endDate - startDate;
          const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

          // Calcular a nova data de término (um dia antes do início do período fornecido)
          const newEndDate = new Date(startDate);
          newEndDate.setDate(startDate.getDate() - 1);

          // Calcular a nova data de início
          const newStartDate = new Date(newEndDate);
          newStartDate.setDate(newEndDate.getDate() - diffDays);

          return [newStartDate.toISOString(), newEndDate.toISOString()];
      },
      onGeoChartReady: function(chart, google) {
        google.visualization.events.addListener(chart, 'select', this.onCountrySelect);
      },
      onCountrySelect() {
        const selection = this.$refs.geoChart.chartObject.getSelection()
        if (selection.length > 0) {
          const row = selection[0].row;
          const country = this.chartByRegion[row + 1][0]; // Row + 1 because the first row is the header
          this.countrySelect = country
          this.customFunction(country);
        }
      },
      savePeriodSelect: function() {
        localStorage.setItem('periodSelect', JSON.stringify(this.periodSelect))
      },
      getPeriod: function() {
        let range = [
          {id: 'today', range: [], days: 0},
          {id: 'last7Days', range: [], days: 7},
          {id: 'last30Days', range: [], days: 30},
          {id: 'last90Days', range: [], days: 90},
          {id: 'allPeriod', range: [], days: null},
        ]

        range.forEach(item => {
          if(item.days != null) {
            var startAt = new Date();
            startAt.setDate(startAt.getDate() - item.days);
            startAt = startAt.toISOString().split('T')[0]
            let endAt = new Date().toISOString().split('T')[0]
            item.range = [
              `${new Date(`${startAt}T00:00:00`).toISOString()}`, 
              `${new Date(`${endAt}T23:59:59`).toISOString()}`
            ]
          }
        })
        return range
      },
      customFunction(country) {
        // alert(`Country selected: ${country}`);
        this.chartByRegionCountry = this.formatRegion(country)
        this.regionDetail = true
        document.getElementById(country).scrollIntoView({behavior: "smooth"});
      },
      formatRegion: function(country) {
        // let data =[['Estado', 'CITY 1', 'Romance', 'Mystery/Crime', 'General', 'Western', 'Literature', { role: 'annotation' }],
        //   ['SP', 10, 24, 20, 32, 18, 5, ''],
        //   ['BA', 16, 22, 23, 30, 16, 9, ''],
        //   ['RJ', 28, 19, 29, 30, 12, 13, '']
        // ]
        var dataCountry =  this.chartByRegionCountry.filter(v => v[0] == country)

        
        let dataMatriz = [['state', this.$t('viewsPerState')]]
        // linha 1
        dataCountry.map(v => v[2] ? v[2] : `${v[1]} ${this.$t('undefined')}`).forEach(v => dataMatriz[0].push(v))
        


        //colluna 1
        dataCountry.map(v => v[1]).filter((value, index, array) => array.indexOf(value) === index).forEach(state => {
          dataMatriz.push([state ? state : this.$t('undefined')].concat(dataMatriz[0].slice(1).map((city) => {
            let views = dataCountry
            //.slice(1)
            .filter(rows => (rows[1] ? rows[1] : this.$t('undefined')) == (state ? state : this.$t('undefined')))// estado
            .filter(rows => (rows[2] ? rows[2] : this.$t('undefined')) == (city ? city : this.$t('undefined'))) // cidade
            .map(rows => rows[3])[0]
            if(views)
              return views
            else if (city == this.$t('viewsPerState'))
              return dataCountry
                .filter(rows => (rows[1] ? rows[1] : this.$t('undefined')) == (state ? state : this.$t('undefined')))
                .map(rows => rows[3])
                .reduce((accumulator, currentValue) => accumulator + currentValue, 0);
            else
              return 0
          })
            ))
        })

        // this.dataMatriz = dataMatriz.filter(row => row.slice(1).filter(c => c != 0)[0])

        return dataMatriz
      },
      getSeriesStates: function (data) {
        return  JSON.parse('{ "' + (data.length -1) +'" : {"type": "line"}}') //JSON.parse(`{ ${data.length -1} : {type: 'line'}}`)
      },
      // Função para preencher datas ausentes
      fillMissingDays: function (dataMatrix) { 
        // Converte a matriz em um objeto para fácil verificação de existência de datas
        let dateMap = new Map(dataMatrix.slice(1).map(entry => [entry[0], entry[1]]));
        let dateMapVisit = new Map(dataMatrix.slice(1).map(entry => [entry[0], entry[2]]));

        var startDate = new Date()
        // Datas de início e fim
        if(dataMatrix[1])
          startDate = new Date(dataMatrix[1][0]);
        let endDate = new Date();
        
        // Array para armazenar os dados preenchidos
        let filledData = [dataMatrix[0]];

        // Loop através do intervalo de datas
        for (let d = new Date(startDate); d <= endDate; d.setDate(d.getDate() + 1)) {
            let dateStr = d.toISOString().split('T')[0];
            if (dateMap.has(dateStr)) {
                filledData.push([dateStr, dateMap.get(dateStr), dateMapVisit.get(dateStr)])
            } else {
                filledData.push([dateStr, 0, 0])
            }
        }

        return filledData;
      },
      // Função para preencher meses ausentes
      fillMissingMonths: function(dataMatrix) {
        // Converte a matriz em um objeto para fácil verificação de existência de meses
        const dateMap = new Map(dataMatrix.slice(1).map(entry => [entry[0], entry[1]]));
        let dateMapVisit = new Map(dataMatrix.slice(1).map(entry => [entry[0], entry[2]]));

        var startDate = new Date()
        // Datas de início e fim
        if(dataMatrix[1]) {
          let date = dataMatrix[1][0]
          startDate = new Date(date.split('-')[0], date.split('-')[1] -1);
        }
        let endDate = new Date();

        // Array para armazenar os dados preenchidos
        const filledData = [["date", "views"]];

        // Loop através do intervalo de meses
        let currentDate = new Date(startDate);
        while (currentDate <= endDate) {
            const yearMonth = currentDate.toISOString().slice(0, 7); // Formata como "YYYY-MM"
            if (dateMap.has(yearMonth)) {
                filledData.push([yearMonth, dateMap.get(yearMonth), dateMapVisit.get(yearMonth)]);
            } else {
                filledData.push([yearMonth, 0, 0]);
            }
            // Avança para o próximo mês
            currentDate.setMonth(currentDate.getMonth() + 1);
        }

        return filledData;
      },
      // Função para preencher anos ausentes
      fillMissingYears: function (dataMatrix) {
        // Converte a matriz em um objeto para fácil verificação de existência de anos
        const yearMap = new Map(dataMatrix.slice(1).map(entry => [entry[0], entry[1]]));
        let dateMapVisit = new Map(dataMatrix.slice(1).map(entry => [entry[0], entry[2]]));

        // Obtém o ano mínimo e máximo da matriz
        var minYear = `${new Date().getFullYear() -1}`
        var maxYear = `${new Date().getFullYear()}`
        if(dataMatrix[1]) {
          minYear = Math.min(...dataMatrix.slice(1).map(entry => entry[0]));
          maxYear = Math.max(...dataMatrix.slice(1).map(entry => entry[0]));
        }

        // Array para armazenar os dados preenchidos
        const filledData = [["date", "views", "unique_views"]];

        // Loop através do intervalo de anos
        for (let year = minYear; year <= maxYear; year++) {
            if (yearMap.has(year)) {
                filledData.push([year, yearMap.get(year), dateMapVisit.get(year)]);
            } else {
                filledData.push([year, 0, 0]);
            }
        }

        return filledData;
      },
      changeTimeUnit: function() {
        localStorage.setItem('dashBoardDateTimeUnit', this.dateAgregation)
        this.getByDate(this.$route.params.id)
      },
      getLocale: function() {
        return navigator.language || navigator.userLanguage
      },
      getPercent: function (dasboardData, dasboardDataPrevius) {
        if(dasboardDataPrevius.clicks  != 0) {
          let percentageIncrease = ((dasboardData.clicks - dasboardDataPrevius.clicks) / dasboardDataPrevius.clicks * 100).toFixed(2)
          let formattedPercentage = ((percentageIncrease >= 0 ? '+' : '') + percentageIncrease).toLocaleString() + '%';
          return formattedPercentage;
        } else {
          return `+${100}%`
        }
      }
    },
  }
</script>
<style scoped>

.vertical-text {
  writing-mode: vertical-rl !important;
}
</style>