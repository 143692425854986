import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

const messages = {
    'pt-br': {
        projectDescriptionShortenUrl : `O Migly é uma ferramenta eficaz para encurtar links. Crie sua conta agora e acesse nosso painel. Use nosso encurtador para transformar URLs longas em links curtos.`,
        shortenUrlBenefit1: 'Encurte links do Instagram, YouTube, WhatsApp ou qualquer outro site.',
        shortenUrlBenefit2: 'Crie um link único que leva para todas as suas redes sociais e projetos.',
        shortenUrlBenefit3: 'Você pode acompanhar a análise e ter controle total sobre seus links.',
        projectDescription : `Encurtador de URL migly é uma ferramenta poderosa para encurtar link. 
        Use o nosso Encurtador de Link para diminuir uma URL longa e gerar um link curto. 
        Crie URLs personalizadas, acompanhe as análises e tenha controle total dos seus links. 
        Crie sua conta agora no link abaixo e acesse nosso painel. 
        Encurte links do Facebook, Instagram, Youtube & Whatsapp. Encurte o link que você quiser.`,
        shortenToURL: 'Encurte seu link',
        shortenToURLExample: 'Exemplo https://www.youtube.com/watch?v=pmnLca3F',
        shortenedUrl: `URL encurtada`,
        originalLink: 'Link original',
        plans: 'Planos',
        shortenAddress: `Encurtar links`,
        uniqueLink: 'Conecte tudo com um único link',
        urlCopiedToClipboard: 'Link copiado',
        copyUrl: 'Copiar link',
        viewUrl: 'Visitar link',
        toClean: 'Limpar',
        invalidUrl : `URL inválida`,
        login: `Entrar`,
        signUp: `Criar Conta`,
        anchorLink1: `redução de urls para economizar espaço`,
        areYouSure: 'Você tem certeza?',
        credit: 'Crédito',
        submissions: 'Envios',
        accessCount: 'Contagem de Acessos',
        accessCountBlockMessage: 'Recurso disponível para assinantes do plano básico do encurtador de URLs.',
        of: 'de',
        smsConfig: 'Configuração dos disparos',
        businessName: 'Nome do negócio, serviço ou programa',
        exemple: 'Exemplo',
        exempleSmsOptIn: 'SMS para solicitação de permissão',
        exempleSmsOptInDesc: 'Gostaria de receber SMS de _? Acesse migly.in/i/xXx para confirmar. Ate aceitar, nao enviaremos mais. O envio de respostas pode gerar custos de dados móveis.',
        exempleSmsMarketing: 'SMS Exemplo de Marketing',
        exempleSmsMarketingDesc: '_: Economize de 25 a 50% em itens em promocao em loja.com. Clique aqui para navegar pela venda: https://loja.com. Click migly.in/o/xXx p/ sair.',
        
        smsTryInfo: `Estamos entusiasmados em oferecer a você a oportunidade de testar nossa API (interface de programação de aplicativos) permite o envio de SMS de forma integrada. Com esta API, você poderá integrar facilmente a capacidade de enviar mensagens de texto para seus clientes ou usuários em seu próprio aplicativo ou plataforma. Para começar o teste gratuito, envie um email solicitando o teste da ferramenta para <b>contato@exatamente-solucoes.com</b>.`,

        businerRequiredInfo: 'Configure os disparos informando o nome do negócio, programa ou serviço para salvar contatos e enviar mensagens.',

        createEbook: 'Crie um e-book',
        createEbookSubtitle: 'Ofereça uma oferta de valor',
        createEbookText: 'Para incentivar os visitantes a compartilharem suas informações de contato, ofereça uma oferta de valor em troca, como um e-book gratuito.',

        operationCannotBeUndone: 'Essa operação não poderá ser desfeita',
        deleteConfirm: 'Confirmar Exclusão',
        searchShortURL: 'Buscar Links',
        save: 'Salvar',
        unique_views: 'Visitantes',
        invalid: 'inválido',
        provideYourEmail: 'Informe seu e-mail',
        'invalid.sms.text.size' : 'A mensagem não pode ultrapassar 160 caracteres',
        editAddress: 'Editar link migly',
        createBio: 'Crie sua página biográfica',
        pageBio: 'Bio Migly',
        followMetrics: 'Acompanhar métricas',
        generateWhatsAppLink: 'Gerar link para WhatsApp',
        whatsAppNumber: 'Número',
        loggout: 'Sair',
        whatsAppText: 'Mensagem Padrão',
        deleteAddressInfoPlan: 'O endereço dos links so podem ser alterados a partir do plano básico',
        toChart: `Analizar clicks nesse link`,
        yourPageAvailibleIn: 'Sua página estará disponível em ',
        panel: 'Painel',
        infoProduct: 'Infoproduto',
        infoProducts: 'Infoprodutos',
        productImage: 'Imagem do produto',
        topicsAndThemes: 'Tópicos, itens e benefícios',
        topicsAndThemesTitle: 'Título dos itens do seu produto',
        topicAndThemesTitle: 'Assunto, tópico, benefício ou item',
        infoproductMoreTitle: 'Mais sobre o seu infoproduto',
        infoproductMoreSubtitle: 'Fale mais sobre o conteúdo do infoproduto',
        fillInFormForReceiveEbook: 'Preencha o formulário para receber o e-book',
        fillInFormForReceiveLink: 'Preencha o formulário para receber o link',
        fillInYourEmailPolicyPrivacy: 'A Exatamente Soluções Educacionais precisa das informações de contato que você nos fornece para contatá-lo e enviar o link do produto. Você pode cancelar a assinatura dessas comunicações a qualquer momento. Para obter informações sobre como cancelar a assinatura, bem como nossas práticas de privacidade e compromisso com a proteção de sua privacidade, consulte nossa Política de Privacidade no final da página.',
        topicsAndThemesSubtitle: 'Descreva os principais tópicos ou áreas de interesse relacionadas ao produto ou serviço e identifique os benefícios ou resultados desejados que o cliente espera obter.',
        product: 'Produto',
        editLinkSubdomain: 'Subdomínio',
        editLinkSubdomainSubtitle: 'O acesso a sua página depende desse subdomínio.',
        productSubtitle: 'Defina qual tipo do infoproduto enviará para seus clientes',
        pageAparence: 'Aparência da página',
        pageAparenceSubtitle: 'Defina a aparência do site do seu infoproduto',
        linkAparencePreview: 'Defina a aparência do link quando compartilhar no whattsapp e redes sociais',
        logoOrlogotipo: 'Logotipo do site ou produto',
        logoOrlogotipoSizeInfo: 'Escolha uma imagem que tenha 300 x 80 pixels ou que respeite essa proporção e tenha o fundo transparente',
        cropImageTitle: 'Cortar e redimensionar imagem',
        subtitle: 'Subtítulo',
        shortDescription: 'Uma breve descrição',
        actionName: 'Nome da ação',
        infoproductType: 'Tipo de infoproduto',
        downloadNow: 'Baixe agora',
        accessNow: 'Tenha acesso agora',
        eBook: 'E-Book',
        file: 'Arquivo',
        editInfoProduct: 'Editar Infoproduto',
        learnMore: 'Saiba mais',
        createInfoProduct: 'Criar Infoproduto',
        send: 'Enviar',
        reportLinkTitle: 'Reporte de Links Suspeitos e Maliciosos',
        reportLinkSendTitle: 'Obrigado por seu relatório!',
        reportLinkSendDescription: 'Agradecemos sua colaboração em manter a web segura. Sua contribuição é essencial para garantir uma experiência online mais confiável e protegida. Nossa equipe analisará o link reportado e tomará as medidas necessárias.',
        reportLinkDescription: 'Ajude-nos a manter a web segura! Utilize este formulário para reportar links com erro, falsos, suspeitos ou potencialmente maliciosos. Sua contribuição é essencial para garantir uma experiência online mais confiável e protegida para todos.',
        reportLinkPlaceholder: 'Por exemplo: O link encurtado direciona para site suspeito de fishing.',
        eBookSendDescription: 'Assim que seus clientes preencherem o formulário de interesse em seu produto, seu arquivo em PDF será prontamente enviado a eles.',
        linkSendDescription: 'Assim que seus clientes preencherem o formulário de interesse em seu produto, seu link será prontamente enviado a eles.',
        createNewInfoproduct: 'Criar novo infoproduto',
        messageToUserNotLoggedInURLShortener: 'Seu link foi gerado com sucesso, no entanto, ele ainda não está associado à sua conta. Como resultado, você não poderá acessar métricas detalhadas nem personalizar a prévia do link nas redes sociais.',
        plansAndPrices: `Planos e preços`,
        plansAndPricesDescription: 'Obtenha uma solução essencial para gerar, compartilhar e rastrear links para todas as formas de comunicação. Escolha seu plano abaixo. Você terá 7 dias para testar.',
        plan_FREE: 'Gratuito',

        URL_SHORTNER_FREE: 'Encurtador de links gratuito',
        URL_SHORTNER_BASIC: 'Encurtador de links básico',
        URL_SHORTNER_PRO: 'Encurtador de links profissional',
        URL_SHORTNER_BUSINESS: 'Encurtador de links empresarial',

        plan_URL_SHORTNER_FREE: 'Gratuito',
        plan_URL_SHORTNER_BASIC: 'Básico',
        plan_URL_SHORTNER_PRO: 'Profissional',
        plan_URL_SHORTNER_BUSINESS: 'Empresarial',

        PLAN_URL_SHORTNER_FREE_SUBSCRIPTION: 'Gratuito',
        PLAN_URL_SHORTNER_BASIC_SUBSCRIPTION: 'Básico',
        PLAN_URL_SHORTNER_PRO_SUBSCRIPTION: 'Profissional',
        PLAN_URL_SHORTNER_BUSINESS_SUBSCRIPTION: 'Empresarial',
        excluded: 'Excluído',

        trialPeriod: 'Se esta for sua primeira ativação, você terá sete dias para testar.',

        LOADING_PAGE: 'Personalização da tela de carregamento',
        LOADING_PAGE_DESC: 'Recurso disponível para assinantes do plano profissional do encurtador de URLs.',

        plan_BASIC: 'Básico',
        plan_PRO: 'Profissional',
        UNLIMITED_LINKS: 'Links ilimitados',
        dateCreateIni: 'Data inicial de criação do link',
        dateCreateEnd: 'Data final de criação do link',
        desc_UNLIMITED_LINKS: {icons: ['mdi-link', 'mdi-all-inclusive'], description: "Não existe limites para a quantidade de links encurtados, você pode criar e encurtar quantos endereços desejar."},
        BIO: 'Bio migly',
        INFOPRODUCT: 'Infoproduto',
        clickDateRange: '',
        true: 'Sim',
        false: 'Não',
        SMS: 'sms (template para envio de SMS)',
        desc_BIO: {icons: ['mdi-bio', 'mdi-vector-union', 'mdi-text-account'], description: "Facilite o acesso a todas suas redes sociais, formas de contato e projetos online com sua página biográfica unificadora de links."},
        QR_CODE: 'QRCodes',
        desc_QR_CODE: {icons: ['mdi-data-matrix-scan'], description: "Facilite ainda mais o acesso a seus links com QRCodes. Com esse recurso seus clientes nem precisa digitar os endereços para suas páginas."},
        UNLIMITED_CLICKS: 'Links sem limite de clicks',
        desc_UNLIMITED_CLICKS: {icons: ['mdi-cursor-default-click', 'mdi-all-inclusive'], description: "Os endereços criados/encurtados sempre estarão disponíveis e nunca expiram, eles também não tem limite para clicks, ou seja, seus clientes ou seguidores sempre terão acesso aos seus links encurtados."},
        COUNT_CLICKS: 'Contagem de clicks',
        desc_COUNT_CLICKS: {icons: ['mdi-cursor-default-click', 'mdi-counter'], description: "Saiba quantas pessoas clicaram em seus links."},
        REPORT_COMPLETE: 'Relatório completo',
        desc_REPORT_COMPLETE: {icons: ['mdi-chart-arc', 'mdi-chart-donut-variant', 'mdi-chart-histogram'], description: "Relatório completo de acessos aos seus links por dispositivo, região e plataforma."},
        LOGO_LOADING: 'Personalização completa',
        desc_LOGO_LOADING: {icons: ['mdi-link', 'mdi-office-building', 'mdi-cog'], description: "Exiba o nome da sua empresa em telas de carregamento e direcionamento para seus links gerados, personalização completa da url e customização da pré-visualização para redes sociais."},
        VERIFY_LINK: 'Verificar links que não funcionam',
        desc_VERIFY_LINK: {icons: ['mdi-link' , 'mdi-cube-scan'], description: "Verificamos links encurtados periodicamente e te avisamos quando um link não está funcinando, assim saberá quando está perdendo acessos."},
        UPDATE_LINK: 'Alterar o direcionamento dos links gerados',
        desc_UPDATE_LINK: {icons: ['mdi-link', 'mdi-circle-edit-outline'], description: "Você poderá editar todos os links encurtados após a criação deles, mudando direcionamento para outro site e até mesmo alterar o nome encurtado do link."},
        SEND_SMS: 'Envio de SMS',
        desc_SEND_SMS: {icons: ['mdi-message-processing' , 'mdi-clock-outline', 'mdi-message-badge-outline'], description: "Enviamos e agendamos envio de SMS para seus clientes. <br> É preciso comprar créditos para enviar mensagens.<br> O custo de cada SMS é de <i>R$ 0,30</i> e você ganha R$ 4,00 todos os meses ao assinar o plano profissional"},
        smsInfo: 'O custo de cada SMS é de <i>R$ 0,30</i>',
        API_ACCESS: 'Acesso a API para integração',
        API_ACCESS_LINK: 'Acesso a API de integração',
        CUSTOM_DOMAIN: 'Domínio personalizado',
        desc_CUSTOM_DOMAIN:  {icons: ['mdi-link', 'mdi-circle-edit-outline'], description: "Você poderá associar um domínio personalizado aos seus links gerados, algo como https://sua-empresa.com/{code}. Será preciso comprar ou configurar um domínio."},
        desc_API_ACCESS: {icons: ['mdi-dev-to', 'mdi-api'], description: "Você poderá encurtar seus links programaticamente utilizando nossa <a href='/api-doc' target='_blank'>API</a>"},
        previewLink: 'Pré-visualização do link',
        getPlan: `Selecionar`,
        'url.rejected' : 'URL rejeitada',
        updateAddressInfo: 'Altere o código do link encurtado ou o direcionamento',
        assignPlan: `Assinar Plano {plan}`,
        assignPlanAuth: `Para assinar esse plano é preciso realizar o login`,
        saved: 'Salvo',
        default: 'Padrão',
        shootings: 'Disparos',
        payPerYearInfo: 'Economize até 30% ao pagar anualmente',
        payPerMonth: 'Pague por mês',
        month: 'mês',
        year: 'ano',
        montly: 'mensal',
        monthly: 'mensal',
        yearly: 'anual',
        payPerYear: 'Pague por ano',
        totalClicks: 'Total de clicks',
        totalClicksInBioPage: 'clicks nos links da sua página',

        optOut: 'opt-out',
        moreActions: 'Mais ações',
        filter: 'Filtrar',
        messageSendoptInRequired: 'Você paga o equivalente ao envio de 1 SMS para solicitar a permissão.',
        optIn: 'opt-in',
        optInRequired: 'Solicite permissão para envios de SMSs',
        optInRequiredSubtitle: 'Este contato atualmente faz parte da nossa lista de exclusão.',
        optInRequiredText: `Para que possamos enviar mensagens para ele, é necessário obter sua permissão. Você pode iniciar o processo enviando uma solicitação de permissão, e só poderemos enviar novas mensagens depois que o contato aceitar receber atualizações.
        `,
        today: 'Hoje',
        DAY: 'Dia',
        MONTH: 'Mês',
        YEAR: 'Ano',
        countries: 'Países',
        states: 'Estados',
        cities: 'Cidades',
        optInRequiredSend: 'Permissão para envios de SMSs solicitada',
        optInRequiredSendSubtitle: 'Este contato atualmente ainda faz parte da nossa lista de exclusão até que ele aceite receber suas comunicações.',
        viewsPerRegion: 'Visualizações por região',
        optInRequiredSendAccept: 'Permissão para envios de SMSs aceita',
        optInRequiredSendAcceptSubtitle: 'Este contato atualmente faz parte da nossa lista de inclusão.',
        requireOptIn: 'Solicitar opt-in',
        optInAcceptTitle: 'Aceitar receber mensagens do(a) ',
        biopage: 'Página biográfica',
        report: 'Relatório',
        views: 'Visualizações',
        viewsPerDate: 'Visualizações por data',
        viewsPerState: 'Visualizações por estado',
        period: 'Período dos acessos',
        shortenFree: 'Entrar com conta google',
        onlineAddressId: 'Identificador do link',
        searchLinksDesc: 'Busque links por trechos da url, tags ou código identificador do link',
        viewsPerDateDisclaimer: '*Gráfico de Acessos Diários: Exibe apenas os dias com acessos registrados. Para ver mais detalhes, clique e expanda o gráfico.',
        countAllLinks: 'Contagem de acesso para todos os links',
        countLinksTo: 'Contagem para o link ',
        browserDefault: 'Navegador Padrão',
        dashboardInfoText: 'Exibição de Dados: O gráfico mostra dados filtrados pelo período de apuração e pela visualização dos links no painel.',

        clicks: 'Clicks',
        appView: 'Outros Apps',
        appViewDesc: 'Visualização dentro de outros aplicativos',

        startAt: 'a partir de',
        endAt: 'até',
        accessByDay: 'Clicks por dia',

        periodWithoutResult: 'Período sem resultado',

        clean: "Limpar",
        plan: "Plano",
        assignPlanCardInfo: "Não mantemos dados do cartão de crédito. Essa informação é utilizada somente na assinatura do plano e depois é excluída.",


        helpTitle: 'Entre em Contato Conosco',
        helpDescription: 'Tem alguma dúvida sobre o Migly, precisa relatar um problema ou quer saber mais sobre nossas funcionalidades? Estamos aqui para ajudar! Entre em contato com nossa equipe pelo e-mail <b>contato@exatamente-solucoes.com</b> e teremos o prazer de assisti-lo. Seu feedback é muito importante para nós!',
        helpAction: 'Envie um email',
        toAccessPanel: 'Acessar painel',
        assignPlanSuccess: "Recebemos os dados do pagamento. Aguarde até que a cobrança seja realizada.",
        youAssignPlan: "Você assina o Plano ",
        'og:title': 'Título da página.',
        'og:type': 'Tipo de conteúdo, como website ou artigo.',
        'og:image': 'Imagem principal da página.',
        'og:url': 'URL da página.',
        'og:description': 'Breve descrição da página.',
        'og:site_name': 'Nome do site.',
        'og:locale': 'Idioma e região da página, como pt_BR.',
        'og:determiner': 'Palavra antes do título, como "a" ou "an".',
        'og:image:url': 'Imagem.',
        'og:image:secure_url': 'Imagem segura (HTTPS).',
        'og:image:type': 'Formato da imagem, como image/jpeg.',
        'og:image:width': 'Largura da imagem em pixels.',
        'og:image:height': 'Altura da imagem em pixels.',
        'og:video': 'Vídeo.',
        'og:video:secure_url': 'Video seguro (HTTPS).',
        'og:video:type': 'Formato do vídeo, como video/mp4.',
        'og:video:width': 'Largura do vídeo em pixels.',
        'og:video:height': 'Altura do vídeo em pixels.',
        'og:audio': 'Áudio.',
        'og:audio:secure_url': 'Áudio seguro (HTTPS).',
        'og:audio:type': 'Formato do áudio, como audio/mpeg.',
        'article:published_time': 'Data e hora de publicação do artigo.',
        'article:modified_time': 'Data e hora da última modificação do artigo.',
        'article:expiration_time': 'Data e hora de expiração do artigo.',
        'article:author': 'URL do autor do artigo.',
        'article:section': 'Seção do site onde o artigo está.',
        'article:tag': 'Palavras-chave do artigo.',
        'book:author': 'URL do autor do livro.',
        'book:isbn': 'Número ISBN do livro.',
        'book:release_date': 'Data de lançamento do livro.',
        'book:tag': 'Palavras-chave do livro.',
        'product:availability': 'Disponibilidade do produto, como "em estoque".',
        'product:price:amount': 'Preço do produto.',
        'product:price:currency': 'Moeda do preço, como BRL.',
        'product:retailer_item_id': 'ID do item no varejista.',
        'contact:already:opt:in:or:number:invalid' : 'Contato já optou por receber mensagens ou o número é inválido',


        'basics': 'Essenciais',
        'optionals': 'Opcionais',
        'images': 'Imagens',
        'videos': 'Vídeos',
        'audios': 'Áudios',
        'articles': 'Artigos',
        'books': 'Livros',
        'products': 'Produtos',

        'basicsDesc': 'Propriedades essenciais para qualquer página.',
        'optionalsDesc': 'Propriedades adicionais que podem ser incluídas.',
        'imagesDesc': 'Propriedades relacionadas a imagens da página.',
        'videosDesc': 'Propriedades relacionadas a vídeos da página.',
        'audiosDesc': 'Propriedades relacionadas a áudios da página.',
        'articlesDesc': 'Propriedades específicas para artigos.',
        'booksDesc': 'Propriedades específicas para livros.',
        'productsDesc': 'Propriedades específicas para produtos.',
        'allDesc': 'Todas as propriedades.',

        hostinger_ads: 'Solução completa para hospedar seu site',
        maxFileSizeOG: 'O tamanho da imagem deve ser inferior a 2 MB!',
        apiRefSwagger: 'Referência da API (Swagger)',
        website: 'Website',
        application: 'Aplicativo',
        audio: 'Audio', 
        image: 'Imagem', 
        delete: 'Excluir',
        deleteLink: 'Excluir Link',
        doUpgrade: 'Fazer upgrade',
        apiDoc: 'Documentação',
        toSendInfoproduct: 'Confira seu email',
        message: 'Mensagem', 
        multipart: 'Arquivo', 
        text: 'Texto',
        video: 'Vídeo',
        domains: 'Domínios',
        domain: 'Domínio',
        sitesShortened: 'Sites encurtados',
        videoOther: 'Vídeos (Outros)',
        regular: 'Regular',
        irregular: 'Irregular',
        deleted: 'Excluído',
        URL_SHORTNER: 'Encurtador de links',
        updateTagsInfo: 'Definir tags para links encurtados pode te ajudar a encontrá-lo na busca com facilidade.',
        toCheck: 'Verificar',
        editLinkTags: 'Editar tags do Link',
        undefined: 'Indefinido',
        undefinedGeoLocation: "*Nem sempre é possível obter o estado e a cidade em que o link foi visualizado. Nesses casos, será exibido como 'Indefinido'.",
        copyYoutubeUrl: 'Use uma url de video do youtube. Exemplo https://www.youtube.com/watch?v=dxVaP0-aFIE ou https://www.youtube.com/embed/dxVaP0-aFIE',
        linksErrorVerify: 'Você tem {0} links encurtados cujo o endereço original podem ter deixado de funcionar',
        thisLinkError: 'Algumas pessoas podem ter dificuldade para acessar esse link. Última verificação em ',
        linksErrorTitle: 'Um ou mais links podem ter deixado de funcionar',
        linkErrorTitle: 'Este link pode ter deixado de funcionar. Verifique as possíveis causas.',
        linkErrorCauses: [
            "O usuário com o link precisa estar logado no site do link original. Obs. LinkedIn, Instagram e outras redes sociais necessitam que esteja logado na plataforma para visualizar certos perfis.",
            "Nem todos os usuários podem ter acesso ao link original.",
            "Link copiado em área logada dentro do site original.",
            "O endereço original pode ter sido alterado.", 
            "Link ou URL não existe mais.",
            "Serviço indisponível.",
        ],
        subdomainAlreadyInUSe: 'Esse suddomínio já está em uso',
        xs: 'Smartphone',
        sm: 'Tablet',
        md: 'Notebook',
        lg: 'Computador',
        xl: 'Televisor',
        notifications: 'Notificações',
        opengraphEditTitle: 'Personalize a Exibição do Link',
        opengraphEditDescription: 'Defina como seu link será exibido nas redes sociais com título, descrição e imagem customizados.',
        possibleCauses: 'Possíveis causas:',
        next_billing_at: "Próxima cobrança em",
        start_at: "Iniciou em",
        interval: "Frequência",
        current_cycle_start_at: "Ciclo de cobrança de ",
        current_cycle_billing_at: "Pagamento em ",
        current_cycle_status: "Situação",
        current_cycle_cycle: "Ciclo",
        clearFilter: 'Limpar filtro',
        shortenAddressTitle: `Encurte seu link`,
        shortenAddressMoreTitle: `seu migly pode ser + que 1 encurtador de links`,
        shortenAddressBenefits: [
            {icon: 'md-'}
        ],
        subscriptions: 'Assinaturas',
        shortenAddressBenefit1: `URL personalizável`,
        shortenAddressBenefit2: 'métrica de acessos',
        shortenAddressBenefit3: 'Personalize a pré-visualização do link para redes sociais',
        shortenAddressBenefit4: 'integração com google analytics',
        billed: 'Faturado',
        viewsDesc: 'Clicks ou visualizações nos links gerados no período selecionado',
        withClicks: 'Links com acessos',
        withClicksDesc: 'Links com pelo menos um acesso. Você ainda pode filtrar por datas de início e fim links com ao menos um acesso.',
        withoutClicks: 'Links sem acessos',
        withoutClicksDesc: 'Links sem acesso. Você ainda pode filtrar por datas de início e fim links sem ao menos uma visualização.',
        unbilled: 'Não faturado',
        active: 'Ativo',
        cancel: 'Cancelar',
        canceled: 'Cancelado',
        updateAddress: 'Alterar link',
        access: 'Acessos',
        failed: 'Falhou',
        future: 'Teste',
        start_in: 'Inicia em',
        metrics: 'Métricas',
        cancelSubscrible: 'Cancelar assinatura',
        confirmCancelSubscrible: 'Confirmar',
        toConfirm: 'Confirmar',
        shorten: 'encurtar',
        status: 'Status',
        originalAddress: 'Endereço original',
        shortenAddressInfo: 'Encurte seu link em segundos e compartilhe de forma prática e elegante. Simples, rápido e gratuito!',
        shortenAddressInfoWitoutLogin: `
        <h3>Seu Link Está Pronto! 🚀</h3>
        <p>
Gostou da facilidade? Agora imagine todas as vantagens que você pode ter criando uma conta com a Migly:

Acompanhe suas métricas: Descubra quantas pessoas estão clicando nos seus links, de onde elas vêm e muito mais.
Personalização total: Crie URLs personalizadas que sejam fáceis de lembrar e compartilhe links com a sua marca.
Segurança e controle: Proteja seus links com senhas e defina datas de expiração.
Automatização inteligente: Encurte links automaticamente enquanto navega e gerencie todos os seus links em um único lugar.
Não perca tempo! Crie sua conta agora e desbloqueie o potencial completo da Migly!
        </p>
        `,
        titleCancel: 'Cancelamento de assinatura',
        textCancel: 'Ao cancelar sua assinatura nós não renovamos no mês subsequente. Os benefícios associados ao tipo de plano estarão disponíveis até o final do ciclo.',
        
        SAVED: 'Salvo',
        segments: 'Segmentos',
        geoChartLineInfo: 'Visualizações por estado',
        geoChartBarInfo: 'Visualizações por cidade',
        name: 'Nome',
        age: 'Idade',
        properties: 'Propriedades',
        OPT_IN_REQUIRED: 'Opt-In Solicitado',
        OPT_IN_SENDED: 'Opt-In Enviado',
        OPT_IN_REQUIRED_ERROR: 'Opt-In com falha',
        OPT_IN: 'Opt-In (Aceitaram suas comunicações)',
        OPT_OUT: 'Opt-Out (Recusaram suas comunicações)',
        viewsPerScreenSize: 'Visualizações por tamanho de tela',
        viewsPerScreenSizeDisclaimer: 'A separação é feita por tamanho de tela e não por dispositivo acessado. ',
        textOptOut: '. Click migly.in/o/xXx p/ sair.',
        displayToWindowSizeDescrition: 'Acesso por dispositivos com base no tamanho da tela',
        optOutTitle: 'Não deseja mais receber mensagens do(a) ',
        displays: [
            {id: 'xs', description: 'Telefone pequeno a grande', icon: 'mdi-cellphone', color: '#304FFE'},
            {id: 'sm', description: 'Tablet pequeno a médio', icon: 'mdi-tablet', color: '#3D5AFE'},
            {id: 'md', description: 'Tablet grande para notebook', icon: 'mdi-laptop', color: '#536DFE'},
            {id: 'lg', description: 'Monitor a TVs grandes', icon: 'mdi-monitor', color: '#2962FF'},
            {id: 'xl', description: 'Telas 4k e ultra-wide', icon: 'mdi-television', color: '#2979FF'}
        ],
        sharedOnViews: 'Visualizações por compartilhamento',
        sharedOnViewsDisclaimer: 'Para coletar essa métrica o link precisa ser compartilhado usando o recurso "Compartilhar no" acessado na lista de links ou na Bio migly.',
        configPageLoadInfo: 'Customize sua página de carregamento',
        'contact:already:opt:out:or:number:invalid' : 'Contato já está na lista de exclusão de comunicação ou número inválido',
        platformTitle: 'Plataforma',
        platformDescription: 'Acesso por plataforma',
        advanced: 'Avançado',
        noData: 'Não encontrado',
        toSendFor: 'Enviar para ',
        SCHEDULED: 'Agendado',
        SENT : 'Enviado',
        ProfitWithUs: 'Lucre conosco',
        PROCESSING: 'Processando',
        processingSmsSend: 'O disparo foi feito, aguarde até enviarmos todas as mensagens.',
        noDataDescription: 'Pesquise por tags, partes da URL ou opções do painel',
        textLimitSms: 'As mensagens tem o limite de 160 caracteres. Alguns mensagens estão superando esse limite. Revise seu template.',
        smsEncodeMessageDescInfo: 'Ao usar palavras acentuadas em suas mensagens o tamanho máximo dela poderá ser reduzido de 160 letras para 70, portanto, tente não utilizar palavras acentuadas.',

        platforms: [
            {id: 'android', color: 'deep-purple accent-4', name: 'Android', icon: 'mdi-android'},
            {id: 'macintosh', color: 'indigo darken-2', name: 'Macintosh (macOS)', icon: 'mdi-apple'},
            {id: 'windows', color: 'blue', name: 'Windows', icon: 'mdi-microsoft-windows'},
            {id: 'iphone', color: 'indigo darken-2', name: 'iPhone (iOS)', icon: 'mdi-apple'},
            {id: 'linux', color: 'primary', name: 'Linux', icon: 'mdi-linux'},
            {id: 'ipad', color: 'indigo darken-2', name: 'iPad (iOS)', icon: 'mdi-apple'},
            {id: 'others', color: 'indigo darken-2', name: 'Outros', icon: 'mdi-crosshairs-question'},



            {id: 'Linux aarch64', color: 'primary', name: '', icon: 'mdi-linux'},
            {id: 'Win32', color: 'blue', name: '', icon: 'mdi-microsoft-windows'},
            {id: 'Linux armv8l', color: 'primary', name: '', icon: 'mdi-linux'},
            {id: 'Linux armv7l', color: 'primary', name: '', icon: 'mdi-linux'},
            {id: 'MacIntel', color: 'indigo darken-2', name: '', icon: 'mdi-apple'},
            {id: 'Linux x86_64', color: 'primary', name: '', icon: 'mdi-linux'},
            {id: 'Linux i686', color: 'primary', name: '', icon: 'mdi-linux'},
            {id: 'Linux armv81', color: 'primary', name: '', icon: 'mdi-linux'},
            {id: 'X11; Linux x86_64', color: 'primary', name: '', icon: 'mdi-linux'},
            {id: 'Win64', color: 'blue', name: '', icon: 'mdi-microsoft-windows'},
            {id: 'PlayStation 4', color: 'red', name: 'PlayStation 4', icon: 'mdi-sony-playstation'},
            {id: 'Windows Phone', color: 'red', name: 'Windows Phone', icon: 'mdi-microsoft-windows'},
            

        ],
        region: 'Região',
        pricing: 'Preço',
        geoTitle: 'Região',
        geoDescription: 'Acesso por região',

        byCountries: 'Paises',
        byStates: 'Estados',
        byCities: 'Cidades',

        onlyIcon: 'apenas ícone',

        shareOn: 'Compartilhar no',
        sharedOn: 'Compartilhado nas redes sociais',

        viewsPerRegionDisclaimer: 'Nem sempre é possível obter a região exata da visualização.',
        copyLink: 'Link copiado',
        viewsPerDomains: 'Visualizações por Domínios',
        withoutDomain: 'Sem domínio',
        shareOptions: [
            {icon: 'mdi-whatsapp', description : 'WhatsApp', url: 'https://api.whatsapp.com/send/?text=', color: '#004D40', value: 'w'},
            {icon: 'mdi-facebook', description : 'Facebook', url: 'https://www.facebook.com/sharer.php?u=' , color: '#3b5998', value: 'f'},
            {icon: 'mdi-twitter', description : 'Twitter', url: 'https://twitter.com/intent/tweet?text=', color: '#00acee', value: 't'},
            {icon: 'mdi-linkedin', description : 'LinkedIn', url: 'https://www.linkedin.com/sharing/share-offsite/?url=', color: '#0e76a8', value: 'l'},
            {icon: 'mdi-facebook-messenger', description : 'Messenger', url: 'https://www.messenger.com/login.php?next=', color: '#008ad3', value: 'm'},
            {icon: 'mdi-email', description : 'Email', url: 'mailto:?subject=&body=', color: 'dark', value: 'e'},
            {icon: 'mdi-qrcode-scan', description : 'QRCode', url: null, color: 'dark', value: 'q'},
        ],
        last7Days: 'Últimos 7 dias',
        last30Days: 'Últimos 30 dias',
        last90Days: 'Último ano',
        allPeriod: 'Todo período',
        config : "Configurações",
        errorIn: 'Situação',
        verificationOptions: {1: 'Irregular', 0: 'Regular', 3: 'Não verificado'},
        typeIn: 'Método de encurtamento',
        CHROME_EXTENSION: 'Extensão do Google Chrome',
        API: 'Application Programming Interface (API)',
        STANDARD: 'Padrão (painel migly)',
        others: 'Outros',
        yourCompanyOrProductHere: 'Descontos de até 30% na Shopee! 🐰 ',
        yourCompanyOrProductHereText: `De acessórios a presentes especiais, encontre tudo que precisa!`,
        callToAction: `🛒 Explore agora.`,
        screenLoading: "Tela de Carregamento",
        screenLoadingDescription: "Customize a tela de carregamento definindo a marca e nome da sua empresa ou do seu produto.",
        logoPicture: "A imagem da sua marca",
        siteName: "Nome do site",
        loaderTimeTitle: "Tempo de espera",
        loaderTimeSubtitle: 'Defina o tempo de espera antes de redirecionar para o destino final.',
        loaderTime: "Tempo de exibição até redirecionamento será de ",
        resourceOnlyPlan: "Recurso disponível somente no plano",
        previewSCreenLoader: "Pré-visualização",
        previewOpenGraph: "Pré-visualização",
        preview: "Pré-visualização",
        editLink: 'Editar link',
        domainAndSubdomain: 'Domínio e subdomínio',
        setDomainAndSubdomain: 'Personalize suas URLs, definindo seu próprio domínio e subdomínio. Esta funcionalidade permite que você utilize sua marca ou identidade visual em cada link encurtado, aumentando a confiança e o reconhecimento da sua marca. Com a possibilidade de usar domínios e subdomínios personalizados, suas URLs podem não ficar mais curtas, mas também mais profissionais e consistentes.',
        setDomainAndSubdomainSuport: 'Para definir um domínio e subdomínio, entre em contato com nosso suporte pelo email contato@exatamente-solucoes.com após assinar o plano empresarial.',
        subdomain: 'Subdomínio',
        editAparenceLink: 'Editar pré-visualização do link',
        editAparenceLinkInfo: 'Esse recurso permite exibir uma pequena pré-visualização para mostrar o conteúdo do link. Aplicativos como facebook, whatsapp e telegram tiram proveito desse recurso.',
        editCodeAndDirectionLink: 'Editar código e direcionamento',
        openGraph: "Personalizar uma visualização de link (Open Graph)",
        openGraphDescription: "Open Graph é uma tecnologia que te permite ter controle sobre como sua página web será compartilhada nas redes sociais.",
        openGraphDescriptionInfo: `Quando o usuário do Facebook curte uma URL com o Open Graph implementado, é criado um vínculo entre o usuário e essa URL.

        Através desse vínculo, será possível interagir com os usuários que a curtiram, da mesma forma que ocorrem as interações com uma página do Facebook.
        
        Por exemplo: publicar atualizações ao usuário, direcionar anúncios e aparecer na seção “Atividades e Interesses” no perfil de quem a curtiu.
        
        Páginas com a implementação enriquecem a experiência do usuário em suas ações de curtir e compartilhar informações.
        
        Além de definir como a página web será representada no feed das redes sociais.`,

        googleAnalitcs: 'Google Analytics',
        googleAnalitcsDescription: 'Você pode integrar seus links encurtados e página biográfica ao google analytics. Veja como obter seu id de medição',
        googleAnalitcsId: 'ID DE MEDIÇÃO',
        googleAnalitcsExample: 'UA-XXXXXX-X ou G-XXXXXXX',
        search: 'Procurar',
        upgradeToPlus: 'Upgrade',
        help: 'Ajuda',
        myAccount: 'Minha Conta',
        settings: 'Configurações',
        links: 'Links',
        link: 'Link',
        newLink: 'Adicionar link',
        newLinkWhatsapp: 'Gerar link para whatsapp',
        page: 'página',
        tags: 'Tags',
        viewsComparePreviusPeriod: 'Em comparação com o período anterior.',
        orCreateAccount: 'Ou crie sua conta',
        lPMarketingAfiliadosLinks: {
            "header": {
                "title": "Migly - Encurtador de URLs e Ferramentas de Marketing",
                "navigation": ["Benefícios", "Recursos", "Preços", "Depoimentos"],
                "ctaButton": "Comece Agora"
            },
            "hero": {
                "headline": "Potencialize seu Marketing de Afiliados",
                "subheadline": "Encurte URLs, vincule links de afiliados e colete métricas com personalização completa.",
                "ctaButton": "Comece Gratuitamente",
                "imageAlt": "Encurtador de URL"
            },
            "benefits": {
                "title": "Benefícios",
                "items": [
                    {
                        "title": "Encurtamento Fácil",
                        "description": "Transforme seus links longos em URLs curtas e elegantes com facilidade."
                    },
                    {
                        "title": "Rastreio de Cliques",
                        "description": "Monitore o desempenho dos seus links com métricas detalhadas."
                    }
                ]
            },
            "features": {
                "title": "Recursos",
                "items": [
                    {
                        "title": "Personalização de Links",
                        "description": "Customize a tela de carregamento e a aparência dos seus links."
                    },
                    {
                        "title": "Integração Fácil",
                        "description": "Integrate seamlessly with your marketing tools."
                    }
                ]
            },
            "pricing": {
                "title": "Planos e Preços",
                "options": [
                    {
                        "name": "Gratuito",
                        "price": "$0 / mês",
                        "features": ["Encurtamento de URLs", "Rastreio básico de cliques"],
                        "ctaButton": "Assine Agora"
                    },
                    {
                        "name": "Premium",
                        "price": "$10 / mês",
                        "features": ["Todos os recursos gratuitos", "Métricas avançadas", "Suporte prioritário"],
                        "ctaButton": "Assine Agora"
                    }
                ]
            },
            "testimonials": {
                "title": "Depoimentos",
                "items": [
                    {
                        "quote": "O Migly transformou a maneira como eu gerencio meus links de afiliados.",
                        "author": "João Silva, Afiliado Profissional"
                    },
                    {
                        "quote": "Ferramenta indispensável para qualquer estratégia de marketing.",
                        "author": "Maria Fernandes, Especialista em Marketing"
                    }
                ]
            },
            "ctaFinal": {
                "headline": "Pronto para Começar?",
                "subheadline": "Junte-se aos milhares de profissionais que já estão aproveitando o Migly.",
                "ctaButton": "Cadastre-se Gratuitamente"
            },
            "footer": {
                "text": "&copy; 2024 Migly. Todos os direitos reservados.",
                "links": ["Política de Privacidade", "Termos de Uso", "Contato"]
            }
        },
        sellInfoproducts: 'Venda infoprodutos',
        generateAuthorization: 'Gerar token de acesso',
        authorization : 'Autorização',
        templateSMS: 'Modelo da mensagem (Texto do SMS)',
        clickToCopy: 'Clique para copiar',
        swaggerUi: 'Veja nossa api doc',
        expiredAt: 'Expira em',
        advancedConfiguration: 'Configurações avançadas',
        sms: 'SMS',
        sendSms: 'disparo de SMS',
        sendSmsTextInfo: 'Alcance seu público com facilidade e segurança. Personalize mensagens, agende disparos e não precisa de assinatura. Assinantes Pro ganham R$4 em créditos para testar. Potencialize seu marketing agora!',
        sendSmsBtn: 'Envie Mensagens',
        comingSoon: 'Em breve',
        sendSMSLeedBtn: 'Entre em contato',
        tryPlan: 'teste 7 dias grátis',
        tryMigly: 'Teste o migly',
        register: 'cadastrar-se',
        analytcsTitle: `desempenho do seu migly`,
        analytcsSubTitle: `acompanhe as estastisticas do seus links`,
        analytcsDescription: `Analytics em encurtadores de links oferecem insights vitais para otimizar estratégias. Medindo cliques, origens e comportamento dos usuários, você maximiza engajamento e ROI. Essencial para tomadas de decisão informadas e sucesso online.`,
        smsCarrosel: [
            {title: 'Saiba quem clicou nos links enviados', icon: 'mdi-link'},
            {title: 'Envie boletos e lembretes de pagamento', icon: 'mdi-cash-sync'},
            {title: 'Divulgue promoções, ofertas e produtos', icon: 'mdi-storefront'},
            {title: 'Compartilhe notícias e informações', icon: 'mdi-share-variant'},
            {title: 'Comunique agendamentos e lembretes', icon: 'mdi-clock-outline'},
            {title: 'Faça pesquisas de mercado', icon: 'mdi-tag-search'},
            {title: 'API de desenvolvedor disponível', icon: 'mdi-api', comingSoon: true}
        ],
        shootingSendMessages: '',
        sendNew: 'Novo disparo',
        buyCreditsAutomatic: 'Comprar créditos automaticamente quando esgotar.',
        alertChangeBusinessName: 'Ao alterar o nome do negócio, serviço ou programa toda sua base de contatos vai automaticamente para o OPT-OUT e só receberão suas mensagens após concordarem em receber atualizações novamente.',
        cashRefund: 'Adicionar créditos',
        simple: 'Simples',
        dynamics: 'Dinâmica',
        dynamicsYourVariables: 'Com as variáveis que você definiu',
        typeOfMessage_simple_desc:'A Mensagem será idêntica para todos os remetentes',
        typeOfMessage_dynamics_desc: 'A Dinâmica apresenta mudança de valor em parte do texto para cada destinatário.',
        typeOfMessage: 'Tipo de mensagem',
        addVariable: 'Adicionar variável',
        addRecipients: 'Adicionar Destinatários',
        addAll: 'Adicionar todos',
        inclusiveAndExclusiveFilter: 'Use o switch a esquerda dos filtros para alternar entre um filtro inclusivo ou exclusivo.',
        inclusiveFilter: 'Contém ao menos uma das opções selecionadas',
        exclusiveFilter: 'Contém todas as opções selecionadas',
        segmentsTypeFilter_false: 'Filtrando por contatos que tenha pelo menos um dos segmentos selecionados',
        segmentsTypeFilter_true: 'Filtrando por contatos que tenha todos segmentos selecionados',
        findNumber: 'Encontrar número',
        required: 'Obrigatório',
        recipients: 'Destinatários',
        recipients_a: 'Destinatário(s)',
        shortenLinks: 'Encurtar links',
        shortenALink: 'Encurtar links',
        toSend: 'Enviar',
        shootingEdit: 'Editar Disparo',
        variableDefinedUnsed: 'Alguma Variável foi definida, mas não é utilizada',
        scheduledShooting: 'Disparo agendado',
        variables: 'Variáveis',
        unusedLinkInMessage: 'Link não utilizado na mensagem',
        campaign: 'Campanha',
        repeatNumber: 'Número repetido',
        exitsRepeatNumber: 'Existem números repetidos em seus destinátarios.',
        excludeRepeatNumber: 'Excluir números repetidos',
        exitsIvalidNumber: 'Existem números inválidos em seus destinátarios.',
        campaignDesc: 'Descreva a sua campanha com um título e uma breve descrição. Essa informação só é visível nesse painel.',
        shooting: 'Disparo de SMS',
        shootingDesc: 'Defina um texto para o SMS, destinatários e quando as mensagens serão enviadas.',
        toScheduledShootingForSend: 'Disparo agendado para ',
        timeZone: 'Fuso horário',
        close: 'Fechar',
        invalidSize: 'Tamanho inválido',
        number: 'Número',
        invalidNumber: 'Número inválido',
        addRecipientByFile: 'Usar CSV',
        problens: 'Problemas',
        loadCsv: 'Carregar CSV',
        FAILURE: 'Falhou no envio',
        accept: 'Aceitar',
        refused: 'Recusar',
        termsOfUse: 'Termos de uso',
        privacyPolicy: 'Política de privacidade',
        sendFailedShooting: 'Sua conta precisa ter créditos os suficiente no momento do disparo das mensagens. Verifique seu saldo.',
        smsTemplateExemples: [
            {title: 'Exemplo de arquivo simples preenchido somente com número do contato', img: '/sms/templates/template-1.png', description: 'obs.: Os campos segmentos e tags não obrigatórios.'},
            {title: 'Exemplo de arquivo preenchido com número, segmentos e tags', img: '/sms/templates/template-2.png', description: 'obs.: Os segmentos e tags são separados por vírgulas.'},
            {title: 'Exemplo de arquivo preenchido com número, segmentos, tags e propriedades do contato', img: '/sms/templates/template-3.png', description: 'obs.: Você pode definir qualquer nome para as respectivas colunas após a coluna Tags, pois essas são colunas referentes as propriedades do contato. nome e idade são somente exemplos, você pode definir quantas propriedades quiser, só tenha em mente que o texto do SMS tem limitação de caracters no caso de usar alguma propriedade na mensagem dinâmica.'}
        ],
        userSignTermsAndPrivacyPolicy: 'Termos de uso e política de privacidade',
        optInAndOptOutInfo: 'Seus contatos cadastrados devem optar por receber suas mensagens.',
        infoSegmentsAndTags: 'Definir Segmentos, tags e propriedades pode te ajudar a agrupar seus contatos para configurar seus disparos de SMS.',
        contacts: 'Contatos',
        downloadFileTemplate: 'Baixar arquivo de template',
        addRecipientByFileDesc: 'Carregue uma lista de contatos a partir de um arquivo CSV. Caso precise, use o modelo abaixo como exemplo.',
        timeZoneDiferneteForThisClient: 'Fuso horário diferente para esse contato',


        youWillBeRedirectedTo: 'Você será redirecionado para',
        cencelRedirected: 'Cancelar direcionamento',
        infoprodutosAndLeeds: 'infoprodutos e leads',
        infoprodutosAndLeedsTextInfo: `Acelere o processo de criação de páginas de coleta de leads, mesmo sem experiência técnica. Utilize modelos otimizados para aumentar suas conversões e expandir sua base de leads. Automatize a geração de leads para impulsionar o marketing. Experimente nosso sistema e transforme visitantes em leads qualificados instantaneamente.`,
        pageType: 'Tipo de Página',
        leedsPage: 'Página para coleta de leads',
        orIn: 'ou em',
        infoproductResourceResponsability: 'Responsabilidade de Uso de PDFs e Imagens',
        infoproductResourceResponsabilityDesc: `<i><p>Ao utilizar PDFs e imagens em nosso sistema, você concorda em assumir total responsabilidade pelo conteúdo e direitos autorais associados a esses arquivos. Certifique-se de possuir os direitos autorais necessários para compartilhá-los e de que seu conteúdo seja adequado e respeite nossos termos de serviço.</p>
        <p>Você é responsável por garantir que nenhum material ilegal, difamatório, obsceno ou ofensivo seja compartilhado. Além disso, certifique-se de não incluir informações confidenciais ou pessoais sem a devida autorização.</p>
        <p>Por favor, esteja ciente de que qualquer violação de direitos autorais, privacidade ou outros direitos legais decorrentes do uso inadequado de PDFs e imagens em nosso sistema será de sua responsabilidade.</p></i>`,
        createYourLeedPage: 'Crie sua página para coleta de leads',
        leedsDescription: 'Uma página de coleta de leads visa capturar informações de visitantes, oferecendo incentivos em troca. Esses dados podem ajudar a converter visitantes em clientes qualificados.',
        title: 'Titulo',
        description: 'Descrição',
        disclosure: 'Divulgação',
        disclosureDescription: 'Aproveite a tela de carregamento para promover seus sites, produtos ou serviços de forma eficaz. Maximize a visibilidade e alcance do seu negócio enquanto seus usuários aguardam o direcionamento!',
        smsInfoLinks: 'Ao incluir uma url no texto da mensagem a ferramenta vai encurta-ló automáticamente e vinculará ao destinatário do SMS, assim você saberá quais clientes acessaram os links',
        leeds: 'Leads',
        imageBanner: 'Banner',
        siteHeader: 'Topo do Site',
        colorSiteHeader: 'Cor do topo do site',
        titleColor: 'Cor do título',
        subtitleColor: 'Cor do subtitulo',
        shortDescriptionColor: 'Cor da breve descrição',
        btnActionColor: 'Cor do botão de ação',
        btnActionTextColor: 'Cor do texto dp botao de ação',
        itensTitleColor: 'Cor do texto to título dos itens',
        itensColor: 'Cor dos itens',
        descriptionBackgroundColor: 'Cor de fundo da descrição',
        descriptionColor: 'Cor da descrição',
        formColor: 'Cor do formulário',
        footerBackgroundColor: 'Cor do fundo do rodapé',
        footerTextColor: 'Cor do texto do rodapé',

        infoproductCreateBy: 'Página criada por',
        infoproductContactTitle: 'Formulário de contato',
        colorInfoproductMore: 'Cor de fundo da descrição',
        novelty: 'Novidade',
        withGoogle: 'Com o Google',
        profile: 'Perfil',
        music: 'Música',
        article: 'Artigo',
        game: 'Game',
        siteType: 'Página web',
        setAllPeriod: 'Informe o período completo',
        setStartPeriod: 'Informe o ínicio do período',
        setEndPeriod: 'Informe o fim do período',
        filterDashBoardTitle: 'Filtro',
        timeUnit: 'Dados agregados por',
        selectEndDate: '--',
        siteTypeSubtitle: 'Defina qual o tipo da página você vai criar/editar.',
        infoproductContactEmailInfo: 'O email sempre fará parte do formulário de contato',
        textField: 'Campo de Texto',
        selecField: 'Seletor',
        textFieldDescription: 'Os componentes dos campos de texto são usados ​​para coletar informações fornecidas pelo usuário.',
        selectFieldDescription: 'Os componentes de campos selecionados são usados ​​para coletar informações fornecidas pelo usuário em uma lista de opções.',
        infoproductFormFieldType: 'Tipo',
        infoproductFormFieldName: 'Nome do campo',
        infoproductFormSelectFildValues: 'Opções disponíveis no seletor',
        infoproductFormFieldValue: 'Valor',
        infoproductFormInputFieldType: `Tipo de entrada`,
        colorfooterBackgroundColor: 'Cor de fundo do radapé',
        alphanumeric: 'Alfanumérico',
        numeric: 'Numérico',
        onlineAddress: 'Endereço online',
        textFieldAlphanumericDescription: 'Uma combinação de caracteres alfabéticos e numéricos',
        selectFieldNumericDescription: 'Uma combinação de caracteres numéricos',
        formField: 'Campo do formulário',
        footerTitle: 'Rodapé do site',
        footerSubtitle: 'Inclua informações da sua empresa, contato, links e mais.',
        infoproductContactSubtitle: 'Desenvolva um formulário personalizado para facilitar o contato direto com seus potenciais clientes. Tenha controle total sobre os campos que compõem o formulário, permitindo que você determine as informações específicas que deseja coletar.',
        everyoneThat: 'Todos que',
        allInFilter: 'Correspondam ao seguinte filtro',
        document: 'Documento',
        contact: 'Contato',
        cpfCnpj: 'CPF/CNPJ',
        purchase: 'Comprar',
        newContacts: 'Pendente inclusão',
        maxSize: 'Tamanho máximo do arquivo deve ser de {0} e o arquivo atual tem {1}.',

        creditCardNumber: 'Número do cartão de crédito',
        cvv: 'CVV',
        creditCardNamePrinted: 'Nome (como escrito no cartão)',
        expiration: 'Expiração',
        billingAddress: 'Endereço de cobrança',
        publicStreet: 'Rua/Logradouro',
        complement: 'Complemento',
        cep: 'CEP',
        city: 'Cidade',
        state: 'Estado',
        creditCard: 'Cartão de crédito',
        ddi: 'DDI',
        ddd: 'DDD',
        advance: 'Avançar',
        toGoBack: 'Voltar',
        invalidCard: 'Cartão inválido',
        all: 'Todos',
        totalCostWillBe: 'O custo total será de <b>_</b>',
        totalCost: 'O custo total: ',
        cards: 'Cartões',
        cardSelection: 'Selecione o cartão',
        addNewCard: 'Adcionar um novo cartão',
        'invalid.dateTimeScheduled' : 'Data e hora do agendamento inválido',

        'brazil.number.only' : 'Apenas números brasileiros são permitidos',
        'number.invalid.value': 'Número inválido',
        cleanInvalidContacts: 'Remover inválidos',
        'insufient.credits' : 'Crédito insuficiente',
        example: 'Exemplo',

        cookiesRequiredInfo: 'Este site usa cookies para melhorar a experiência de navegação, analisar dados, fazer pesquisas e direcionar propagandas. Para alterar as preferências, clique em "Gerenciar preferências". Ao clicar em "Aceitar todos os cookies", você concorda com o uso de cookies no seu dispositivo. <span id="onetrust-reject-all-banner-desc">Clicar em "Rejeitar todos os cookies" indica que você não concorda com o uso de cookies que não sejam estritamente necessários no seu dispositivo</span>.<a class="link" href="/privacy-policy" aria-label="Mais informações sobre a sua privacidade, abre em uma nova guia" rel="noopener" target="_blank"> Aviso de cookies e rastreamento do migly</a>.',
        metricsAndTimeLine: 'Métricas e linha do tempo',
        'large.sms.text' : 'Algumas mensagens ultrapassam o limite de 160 caracters. Revise seu texto.',
        sendRequireOptInAllSavedInfo: 'Existem _ contatos na lista de exclusão. Para podermos enviar mensagens para eles, é necessário obter permissão. Você pode iniciar o processo enviando uma solicitação de permissão para todos eles, e só poderemos enviar novas mensagens após o contato aceitar receber atualizações. Para solicitar a permissão, é necessário pagar o valor equivalente ao envio de 1 SMS por contato.',
        sendRequireOptInAllSaved: 'Enviar OPT-IN para todos os ',
        last4NumberDigits: 'Informe os 4 últimos dígitos do seu celular',
        smsHelp: 'Dificuldade em utilizar o recurso de envio de SMS em massa? Entre em contato <a class="link" href="emailto:contato@exatamente-solucoes.com">contato@exatamente-solucoes.com</a>',
        confirmOptInMessage: '<p>Você optou por receber as mensagens do(a) _.</p><p>A cada mensagem recebida você pode optar por não receber mais simplesmente clicando no link dos SMSs recebidos.</p>',
        confirmOptOutMessage: '<p>Você optou por não receber as mensagens do(a) _.</p>',
        dynamicMessageAlert: 'O SMS não pode ultrapassar o limite de 160 caracters. Ao definir a mensagem como dinâmica a variável no corpo do texto não pode fazer com que o SMS ultrapasse esse limite.',
        scheduleShootingFor: 'Agendar disparo para',
        contactClickedLinks: 'Contatos clicaram neste link',
        googleChromeWebStoreMiglyTitleXS: 'Encurte links',
        manageCookiesPreferencies: 'Gerenciar preferências',
        rejectCookiesPreferencies: 'Rejeitar todos os cookiess',
        acceptAllCookiesPreferencies: 'Aceitar todos os cookies',
        manageCookiesTitle: 'Centro de preferências de privacidade',
        manageCookiesDescription: `<div id="ot-pc-desc">Quando você visita um site, ele pode armazenar ou coletar informações no seu navegador, principalmente na forma de cookies. Essas informações podem ser sobre você, suas preferências ou seu dispositivo e são usadas principalmente para fazer o site funcionar como você espera. As informações normalmente não o identificam diretamente, mas podem oferecer uma experiência na web mais personalizada. Como respeitamos seu direito à privacidade, você pode optar por não permitir alguns tipos de cookies. Clique nos títulos das diferentes categorias para saber mais e alterar nossas configurações padrão. No entanto, o bloqueio de alguns tipos de cookies pode afetar sua experiência no website e os serviços que podemos oferecer.
            <br><a href="/privacy-policy" class="link" rel="noopener" target="_blank" aria-label="Mais informações sobre a sua privacidade, abre em uma nova guia" tabindex="0"> Mais informações</a></div>`,



        cookie_necessary : 'Cookies estritamente necessários',    
        cookie_analytics : 'Cookies de desempenho',
        cookie_advertising : 'Cookies de publicidade',
        cookie_research : 'Cookies de funcionalidade',
        cookie_necessary_desc : 'Estes cookies são necessários para que o website funcione e não podem ser desligados nos nossos sistemas. Normalmente, eles só são configurados em resposta a ações levadas a cabo por si e que correspondem a uma solicitação de serviços, tais como definir as suas preferências de privacidade, iniciar sessão ou preencher formulários. Pode configurar o seu navegador para bloquear ou alertá-lo(a) sobre esses cookies, mas algumas partes do website não funcionarão. Estes cookies não armazenam qualquer informação pessoal identificável.',    
        cookie_analytics_desc : 'Estes cookies permitem-nos contar visitas e fontes de tráfego, para que possamos medir e melhorar o desempenho do nosso website. Eles ajudam-nos a saber quais são as páginas mais e menos populares e a ver como os visitantes se movimentam pelo website. Todas as informações recolhidas por estes cookies são agregadas e, por conseguinte, anónimas. Se não permitir estes cookies, não saberemos quando visitou o nosso site.',
        cookie_advertising_desc : 'Estes cookies podem ser estabelecidos através do nosso site pelos nossos parceiros de publicidade. Podem ser usados por essas empresas para construir um perfil sobre os seus interesses e mostrar-lhe anúncios relevantes em outros websites. Eles não armazenam diretamente informações pessoais, mas são baseados na identificação exclusiva do seu navegador e dispositivo de internet. Se não permitir estes cookies, terá menos publicidade direcionada.',
        cookie_research_desc : 'Estes cookies permitem que o site forneça uma funcionalidade e personalização melhoradas. Podem ser estabelecidos por nós ou por fornecedores externos cujos serviços adicionámos às nossas páginas. Se não permitir estes cookies algumas destas funcionalidades, ou mesmo todas, podem não atuar corretamente.',

        googleChromeWebStoreMiglyTitle: 'melhore sua experiência online com nossa extensão!',
        googleChromeWebStoreMiglySubTitle: 'descubra recursos incríveis e navegue de forma mais eficiente. nossa extensão é rápida, segura e fácil de usar!',
        googleChromeWebStoreMiglyAction: 'instale agora!',
        reportLink: 'Reportar Link',
        termsOfUseHtml : {
            '1.0.0' : `
                <h1>TERMOS DE USO</h1>
                <p style="margin-top: 15px"><i><strong>Última atualização: DATEUPDATE</strong></i></p>
                <p>Bem-vindo ao <strong>Migly</strong>. Estes Termos de Uso regem o uso do Sistema e constituem um acordo legalmente vinculativo entre você e a <strong>Exatamente Soluções Educacionais</strong>.</p>
               
               
                <h2><p>1. Aceitação dos Termos</p></h2>
                <p>Ao acessar ou utilizar o Sistema, você concorda com estes Termos e concorda em cumprir todas as leis e regulamentos aplicáveis. Se você não concorda com estes Termos, não utilize o Sistema.</p>
            
                <h2><p>2. Uso do Sistema</p></h2>
                <h3>2.1. Encurtamento de URLs</h3>
                <p>O Sistema permite que você encurte URLs. Você concorda em usar essa funcionalidade de maneira ética e legal, sem disseminar links maliciosos ou conteúdo ilegal.</p>
            
                <h3>2.2. Disparo de SMS em Massa</h3>
                <p>Se Você utilizar a funcionalidade de disparo de SMS em massa, é responsável por cumprir todas as leis de proteção de dados e regulamentos aplicáveis, incluindo obter o consentimento adequado dos destinatários.</p>
            
                <h2><p>3. Conta do Usuário</p></h2>
                <h3>3.1. Criação de Conta</h3>
                <p>Você pode ser solicitado a criar uma conta para acessar determinadas funcionalidades do Sistema. Você é responsável por manter a confidencialidade de suas informações de conta e por todas as atividades que ocorram sob sua conta.</p>
            
                <h3>3.2. Exclusão de Conta</h3>
                <p>Reservamo-nos o direito de encerrar ou suspender sua conta a nosso critério, se suspeitarmos de atividades fraudulentas, ilegais ou violações destes Termos.</p>
            
                <h2><p>4. Privacidade</p></h2>
                <p>Suas informações pessoais estão sujeitas à nossa Política de Privacidade.</p>
            
                <h2><p>5. Propriedade Intelectual</p></h2>
                <h3>5.1. Propriedade</h3>
                <p>A Exatamente Soluções Educacionais detém todos os direitos de propriedade intelectual relacionados ao Sistema.</p>
            
                <h3>5.2. Abstenção</h3>
                <p>Você concorda em não copiar, reproduzir, distribuir ou criar trabalhos derivados com base no Sistema sem permissão expressa da Exatamente Soluções Educacionais.</p>
            
                <h2><p>6. Limitação de Responsabilidade</p></h2>
                <h3>6.1. Garantias</h3>
                <p>O Sistema é fornecido "como está", sem garantias de qualquer tipo, expressas ou implícitas.</p>
            
                <h3>6.2. Responsabilidade</h3>
                <p>A Exatamente Soluções Educacionais não se responsabiliza por quaisquer danos diretos, indiretos, incidentais, especiais, consequentes ou punitivos decorrentes do uso do Sistema.</p>
            
                <h2><p>7. Disposições Gerais</p></h2>
                <h3>7.1. Termos de uso anteriores</h3>
                <p>Estes Termos representam o acordo integral entre Você e a Exatamente Soluções Educacionais e substituem todos os acordos anteriores.</p>
            
                <h3>7.2. Atualizações</h3>
                <p>Estes Termos podem ser alterados pela Exatamente Soluções Educacionais a qualquer momento, mediante aviso prévio.</p>
            
                <h3>7.3. Jurisdição</h3>
                <p>Estes Termos serão regidos pelas leis do Brasil, Guarapuava - PR e qualquer litígio será submetido à jurisdição exclusiva dos tribunais desta jurisdição.</p>
            
                <p>Ao utilizar o Sistema, Você concorda com estes Termos e está ciente de que o não cumprimento deles pode resultar na suspensão ou encerramento de sua conta. Se tiver alguma dúvida ou preocupação, entre em contato conosco em contato@exatamente-solucoes.com.</p>
            `,
            '1.0.1' : `
    <h1>Termos de Uso</h1>
<p style="margin-top: 15px"><i><strong>Última atualização: DATEUPDATE</strong></i></p>
    <h2>1. Descrição do Serviço</h2>
    <p>O Migly é um encurtador de URLs que possibilita a criação de um agregador de links (link biográfico) e coleta métricas de acesso. Também permite a personalização de URLs e o aproveitamento do tempo de direcionamento para divulgação ou publicidade.</p>

    <h2>2. Condições de Uso</h2>
    <p>Para usar o serviço, o usuário deve:</p>
    <ul>
        <li>Ter no mínimo 18 anos</li>
        <li>Estar em conformidade com as leis locais</li>
    </ul>

    <h2>3. Restrições de Uso</h2>
    <p>Os usuários estão proibidos de:</p>
    <ul>
        <li>Usar o serviço para fins ilegais</li>
        <li>Enviar spam</li>
        <li>Violar direitos de terceiros</li>
        <li>Direcionar para jogos de apostas, pornografia e qualquer ato considerado crime internacional e em território nacional (Brasil)</li>
    </ul>

    <h2>4. Propriedade Intelectual</h2>
    <p>Todo o conteúdo e funcionalidade da aplicação Migly, incluindo, mas não se limitando a textos, gráficos, logotipos e imagens, são de propriedade exclusiva da Exatamente Soluções ou de seus licenciadores e são protegidos pelas leis de propriedade intelectual.</p>

    <h2>5. Limitação de Responsabilidade</h2>
    <p>Nem Migly nem a Exatamente Soluções se responsabilizam pelo endereço final do link encurtado ou por danos causados pelos direcionamentos.</p>

    <h2>6. Modificações no Serviço</h2>
    <p>As políticas serão atualizadas e sempre informadas ao usuário caso alguma regra ou recurso do sistema mude. Os usuários serão notificados após login no painel.</p>

    <h2>7. Rescisão</h2>
    <p>O contrato pode ser rescindido a qualquer momento. No entanto, como o pagamento é pré-pago, após o período de avaliação (sete dias) não será feito o reembolso. O cliente deve ficar atento aos ciclos de pagamento.</p>

    <h2>8. Contato</h2>
    <p>Para suporte e questões relacionadas ao uso da aplicação, entre em contato pelo email: <a href="mailto:contato@exatamente-solucoes.com">contato@exatamente-solucoes.com</a>.</p>

    <h2>Extras</h2>
    <p><strong>Jurisdicional e Legislação Aplicável:</strong> Os presentes Termos de Uso são regidos pelas leis do Brasil. Quaisquer disputas serão resolvidas na jurisdição brasileira.</p>
    <p><strong>Atualizações:</strong> Os usuários serão informados sobre atualizações neste documento através de notificação após login no painel.</p>
            `,
            '1.0.2' : `
            <h1>Termos de Uso</h1>
<p style="margin-top: 15px"><i><strong>Última atualização: DATEUPDATE</strong></i></p>

<h2>1. Descrição do Serviço</h2>
<p>O Migly é um encurtador de URLs que possibilita a criação de um agregador de links (link biográfico) e coleta métricas de acesso. Também permite a personalização de URLs e o aproveitamento do tempo de direcionamento para divulgação ou publicidade.</p>

<h2>2. Condições de Uso</h2>
<p>Para usar o serviço, o usuário deve:</p>
<ul>
    <li>Ter no mínimo 18 anos</li>
    <li>Estar em conformidade com as leis locais</li>
</ul>

<h2>3. Restrições de Uso</h2>
<p>Os usuários estão proibidos de:</p>
<ul>
    <li>Usar o serviço para fins ilegais</li>
    <li>Enviar spam</li>
    <li>Violar direitos de terceiros</li>
    <li>Direcionar para jogos de apostas, phishing, pornografia e qualquer ato considerado crime internacional e em território nacional (Brasil)</li>
</ul>
<p>Contas que violarem essas regras poderão ser desativadas sem aviso prévio.</p>

<h2>4. Propriedade Intelectual</h2>
<p>Todo o conteúdo e funcionalidade da aplicação Migly, incluindo, mas não se limitando a textos, gráficos, logotipos e imagens, são de propriedade exclusiva da Exatamente Soluções ou de seus licenciadores e são protegidos pelas leis de propriedade intelectual.</p>

<h2>5. Limitação de Responsabilidade</h2>
<p>Nem Migly nem a Exatamente Soluções se responsabilizam pelo endereço final do link encurtado ou por danos causados pelos direcionamentos.</p>

<h2>6. Modificações no Serviço</h2>
<p>As políticas serão atualizadas e sempre informadas ao usuário caso alguma regra ou recurso do sistema mude. Os usuários serão notificados após login no painel.</p>

<h2>7. Rescisão</h2>
<p>O contrato pode ser rescindido a qualquer momento. No entanto, como o pagamento é pré-pago, após o período de avaliação (sete dias) não será feito o reembolso. O cliente deve ficar atento aos ciclos de pagamento.</p>

<h2>8. Contato</h2>
<p>Para suporte e questões relacionadas ao uso da aplicação, entre em contato pelo email: <a href="mailto:contato@exatamente-solucoes.com">contato@exatamente-solucoes.com</a>.</p>

<h2>Extras</h2>
<p><strong>Jurisdicional e Legislação Aplicável:</strong> Os presentes Termos de Uso são regidos pelas leis do Brasil. Quaisquer disputas serão resolvidas na jurisdição brasileira.</p>
<p><strong>Atualizações:</strong> Os usuários serão informados sobre atualizações neste documento através de notificação após login no painel.</p>
            `
        },
        privacyPolicyHtml : {
            '1.0.0' : `
                <h1>POLÍTICA DE PRIVACIDADE</h1>
                <p style="margin-top: 15px"><strong>Última atualização: DATEUPDATE</strong></p>
                
                <p>A sua privacidade é importante para nós. Esta Política de Privacidade descreve como a Exatamente Soluções Educacionais coleta, utiliza, compartilha e protege as informações pessoais dos usuários ao utilizar o sistema Migly.</p>
            
                <h2><p>1. Informações Coletadas</p></h2>
                <h3>1.1. Informações de Registro</h3>
                <p>Quando você cria uma conta no Sistema, podemos coletar informações pessoais, como nome, endereço de e-mail e senha.</p>
            
                <h3>1.2. Informações de Uso</h3>
                <p>Podemos coletar informações sobre como você utiliza o Sistema, incluindo os URLs encurtados e as mensagens SMS enviadas.</p>
            
                <h3>1.3. Informações de Pagamento</h3>
                <p>Podemos coletar informações de pagamento, como dados de cartão de crédito, endereço de cobrança e CPF.</p>
            
                <h2><p>2. Uso das Informações</p></h2>
                <h3>2.1. Fornecimento e Melhoria do Serviço</h3>
                <p>Utilizamos suas informações para fornecer e melhorar o Sistema, incluindo o encurtamento de URLs e o disparo de SMS.</p>
            
                <h3>2.2. Comunicações</h3>
                <p>Podemos usar seu endereço de e-mail para enviar informações sobre o Sistema, atualizações e notícias relevantes.</p>
            
                <h2><p>3. Compartilhamento de Informações</p></h2>
                <p>Não compartilhamos suas informações pessoais com terceiros, a menos que seja necessário para fornecer os serviços solicitados por você ou conforme exigido por lei.</p>
            
                <h2><p>4. Segurança das Informações</p></h2>
                <p>Tomamos medidas razoáveis para proteger suas informações pessoais contra acesso não autorizado, alteração, divulgação ou destruição.</p>
            
                <h2><p>5. Acesso e Controle das Suas Informações</p></h2>
                <p>Você pode acessar, corrigir ou excluir suas informações pessoais a qualquer momento, entrando em contato conosco através das informações fornecidas no final desta Política de Privacidade.</p>
            
                <h2><p>6. Cookies e Tecnologias Semelhantes</p></h2>
                <p>O Sistema pode usar cookies e tecnologias semelhantes para melhorar a experiência do usuário. Você pode controlar o uso de cookies nas configurações do seu navegador.</p>
            
                <h2><p>7. Alterações na Política de Privacidade</p></h2>
                <p>Reservamo-nos o direito de atualizar esta Política de Privacidade a qualquer momento. A data da última atualização será indicada no início deste documento.</p>
            
                <h2><p>8. Contato</p></h2>
                <p>Se tiver dúvidas sobre esta Política de Privacidade ou desejar exercer seus direitos de privacidade, entre em contato conosco em:</p>
                <address>
                    <p>AL. ELIO ANTONIO DALLA VECCHIA, 838 Sala 5, 85.040-167, CASCAVEL, GUARAPUAVA - PR
                    <br>
                    contato@exatamente-solucoes.com</p>
                </address>
            `,
            '1.0.1' : `
    <h1>Política de Privacidade</h1>
<p style="margin-top: 15px"><i><strong>Última atualização: DATEUPDATE</strong></i></p>
    <h2>1. Informações Coletadas</h2>
    <p><strong>Dados Pessoais:</strong></p>
    <ul>
        <li>Nome</li>
        <li>Email</li>
        <li>Foto de perfil</li>
        <li>Para assinantes: CIF, nome completo, dados do cartão de crédito (intermediados pelo sistema de pagamento Pagar.me)</li>
    </ul>
    <p><strong>Dados de Navegação:</strong></p>
    <ul>
        <li>URLs acessadas e abas abertas no navegador para usuários da extensão para Google Chrome</li>
    </ul>

    <h2>2. Uso das Informações</h2>
    <p>As informações coletadas são utilizadas para:</p>
    <ul>
        <li>Personalização de serviços</li>
        <li>Melhoria da aplicação</li>
        <li>Análises estatísticas</li>
        <li>Coleta e publicidade direcionada para usuários do Migly que clicam no link encurtado</li>
    </ul>
    <p>Não compartilhamos essas informações com terceiros.</p>

    <h2>3. Armazenamento e Segurança</h2>
    <p>As informações são armazenadas em bancos de dados seguros e servidores protegidos. Os dados de pagamento são mantidos pela Pagar.me. Para mais informações sobre a política de privacidade da Pagar.me, consulte <a href="https://migly.in/xC61">aqui</a>.</p>

    <h2>4. Direitos dos Usuários</h2>
    <p>Os usuários têm direito a:</p>
    <ul>
        <li>Acesso aos seus dados</li>
        <li>Correção de dados incorretos</li>
        <li>Exclusão dos seus dados</li>
    </ul>
    <p>Os usuários podem exercer esses direitos enviando um email para: <a href="mailto:contato@exatamente-solucoes.com">contato@exatamente-solucoes.com</a>.</p>

    <h2>5. Cookies e Tecnologias Semelhantes</h2>
    <p>Utilizamos cookies para:</p>
    <ul>
        <li>Direcionar publicidade aos usuários dos links encurtados</li>
        <li>Coletar preferências de consumo</li>
    </ul>
    <p>Os dados coletados não são correlacionados a um usuário especificamente.</p>

    <h2>6. Contato</h2>
    <p>Para questões relacionadas à privacidade, entre em contato com Exatamente Soluções pelo email: <a href="mailto:contato@exatamente-solucoes.com">contato@exatamente-solucoes.com</a>.</p>

    <h2>Extras</h2>
    <p><strong>Jurisdicional e Legislação Aplicável:</strong> A presente Política de Privacidade é regida pelas leis do Brasil. Quaisquer disputas serão resolvidas na jurisdição brasileira.</p>
    <p><strong>Atualizações:</strong> Os usuários serão informados sobre atualizações neste documento através de notificação após login no painel.</p>            
            `,
            '1.0.2' : `
            <h1>Política de Privacidade</h1>
<p style="margin-top: 15px"><i><strong>Última atualização: DATEUPDATE</strong></i></p>

<h2>1. Informações Coletadas</h2>
<p><strong>Dados Pessoais:</strong></p>
<ul>
    <li>Nome</li>
    <li>Email</li>
    <li>Foto de perfil</li>
    <li>Para assinantes: CIF, nome completo, dados do cartão de crédito (intermediados pelo sistema de pagamento Pagar.me)</li>
</ul>
<p><strong>Dados de Navegação:</strong></p>
<ul>
    <li>URLs acessadas e abas abertas no navegador para usuários da extensão para Google Chrome</li>
</ul>

<h2>2. Uso das Informações</h2>
<p>As informações coletadas são utilizadas para:</p>
<ul>
    <li>Personalização de serviços</li>
    <li>Melhoria da aplicação</li>
    <li>Análises estatísticas</li>
    <li>Coleta e publicidade direcionada para usuários do Migly que clicam no link encurtado</li>
</ul>
<p>Não compartilhamos essas informações com terceiros.</p>

<h2>3. Armazenamento e Segurança</h2>
<p>As informações são armazenadas em bancos de dados seguros e servidores protegidos. Os dados de pagamento são mantidos pela Pagar.me. Para mais informações sobre a política de privacidade da Pagar.me, consulte <a href="https://migly.in/xC61">aqui</a>.</p>

<h2>4. Direitos dos Usuários</h2>
<p>Os usuários têm direito a:</p>
<ul>
    <li>Acesso aos seus dados</li>
    <li>Correção de dados incorretos</li>
    <li>Exclusão dos seus dados</li>
</ul>
<p>Os usuários podem exercer esses direitos enviando um email para: <a href="mailto:contato@exatamente-solucoes.com">contato@exatamente-solucoes.com</a>.</p>

<h2>5. Cookies e Tecnologias Semelhantes</h2>
<p>Utilizamos cookies para:</p>
<ul>
    <li>Direcionar publicidade aos usuários dos links encurtados</li>
    <li>Coletar preferências de consumo</li>
</ul>
<p>Os dados coletados não são correlacionados a um usuário especificamente.</p>

<h2>6. Contato</h2>
<p>Para questões relacionadas à privacidade, entre em contato com Exatamente Soluções pelo email: <a href="mailto:contato@exatamente-solucoes.com">contato@exatamente-solucoes.com</a>.</p>

<h2>Extras</h2>
<p><strong>Jurisdicional e Legislação Aplicável:</strong> A presente Política de Privacidade é regida pelas leis do Brasil. Quaisquer disputas serão resolvidas na jurisdição brasileira.</p>
<p><strong>Atualizações:</strong> Os usuários serão informados sobre atualizações neste documento através de notificação após login no painel.</p>

            `
        },
        creationDate: 'Criação do link',
        filters: 'Filtros',
        addressType: 'Método de encurtamento',
        addressTypeDesc: 'É a fonte que gerou o link curto.',

        addressType_ALL: 'Todos os tipos',
        addressType_API: 'API',
        addressType_CHROME_EXTENSION: 'Extensão do Google Chrome',
        addressType_STANDARD: 'Este Painel (site)',
        addressType_BIO: 'Biolink (meu migly)',
        addressType_SMS: 'SMSs',
        addressType_INFOPRODUCT: 'Infoprodutos',
        addressTypeDesc_API: 'Links encurtados pela API de desenvolvedor',
        addressTypeDesc_CHROME_EXTENSION: 'Links encurtados a partir d extensão do Google Chrome',
        addressTypeDesc_STANDARD: 'Links ecurtados por este Painel',
        addressTypeDesc_BIO: 'Links associados ao seu biolink (meu migly)',
        addressTypeDesc_SMS: 'Links encurtados associados ao disparo e agendamento de SMSs',
        addressTypeDesc_INFOPRODUCT: 'Links para seus Infoprodutos',
        orderBy: 'Ordenar',
        dateCreateOrderByASC: 'Mais antigos',
        dateCreateOrderByDESC: 'Mais recentes',
        clicksOrderByASC: 'Menos acessados',
        clicksOrderByDESC: 'Mais acessados',
        share: 'Compartilhar',
        situation: 'Situação',
        situationDesc: 'Todos os dias é feita uma verificação dos endereços dos links encurtados, quando um link falha na verificação a situação passa a ser irregular.',
        clear: 'Limpar',
        apply: 'Aplicar',
        creationDateDesc: 'Datas de quando o link foi gerado. Você pode limitar por datas início e fim.',
        results: 'resultados',
        notVerified: 'Não verificado',
        withoutNotifications: 'Sem notificações',
        createYoutMiglyPage: `Crie sua página migly`,
        shortenWithMigly: 'Encurte com o migly',
        newsMiglyTitle: 'Novidades do migly',
        newsMiglySubtitle: 'Encurtamento, personalização e coleta de métricas',
        SHORTEN_LINKS: 'Encurtador',
        newsMigly: {
            title: 'Novidades do migly',
            subtitle: 'Encurtamento, personalização e coleta de métricas',
            news: [
                {
                    type: 'SHORTEN_LINKS', 
                    title: 'Personalize seus links e simplifique seus compartilhamentos',
                    text: `<p>Transforme suas URLs longas em links curtos e personalizados com nosso sistema de encurtamento. Configure a pré-visualização perfeita para suas redes sociais, definindo imagens e descrições personalizadas para compartilhamentos no WhatsApp, Instagram e outras plataformas. Torne cada link atraente e eficaz, garantindo que sua mensagem seja destacada e cativante!</p>
                        <ul>
                        <li><strong>Aumente o engajamento:</strong> Links visualmente atraentes incentivam mais cliques e compartilhamentos.</li>
                        <li><strong>Controle a aparência:</strong> Personalize imagens e descrições para se alinhar com sua marca.</li>
                        <li><strong>Facilite o compartilhamento:</strong> URLs curtas são mais fáceis de lembrar e compartilhar.</li>
                        </ul>
                        `,
                    img: '/images/news/001.jpeg'
                },
                {
                    type: 'SHORTEN_LINKS', 
                    title: 'Vantagens de Usar Links Curtos',
                    text: `
                        <p>Utilizar links curtos traz uma série de benefícios para suas campanhas e compartilhamentos online. Com nossos links extremamente curtos, você pode aproveitar ao máximo cada um desses benefícios:</p>
                        <ul>
                        <li><strong>Facilidade de Compartilhamento:</strong> Links curtos são mais fáceis de lembrar, digitar e compartilhar em qualquer plataforma.</li>
                        <li><strong>Economia de Espaço:</strong> Ideal para redes sociais com limites de caracteres, permitindo que você foque mais na sua mensagem.</li>
                        <li><strong>Estética Aperfeiçoada:</strong> Links curtos são visualmente mais atraentes e profissionais, melhorando a aparência das suas postagens.</li>
                        <li><strong>Aumento de Cliques:</strong> Links mais curtos tendem a receber mais cliques, impulsionando o tráfego para seu conteúdo.</li>
                        </ul>
                    `,
                    img: '/images/news/002.png'
                },
                {
                    type: 'SHORTEN_LINKS', 
                    title: 'Personalize Sua Tela de Carregamento',
                    text: `
<p>Aproveite ao máximo o recurso de customização da tela de carregamento e destaque sua marca em cada clique. Com a possibilidade de definir sua própria marca e um banner para publicidade, você pode transformar a experiência de carregamento em uma poderosa ferramenta de marketing. Confira os benefícios:</p>
<ul>
  <li><strong>Reforço da Marca:</strong> Exiba sua identidade visual de forma consistente, aumentando o reconhecimento e a lembrança da marca.</li>
  <li><strong>Monetização:</strong> Utilize banners publicitários para gerar receita adicional, aproveitando o tempo de carregamento dos links.</li>
  <li><strong>Engajamento do Usuário:</strong> Capte a atenção dos usuários com mensagens personalizadas e ofertas especiais durante o carregamento.</li>
  <li><strong>Experiência Personalizada:</strong> Crie uma experiência única para seus usuários, alinhada com seus objetivos de marketing.</li>
  <li><strong>Fidelização:</strong> Utilize a tela de carregamento para promover campanhas e aumentar a fidelidade dos clientes.</li>
  <li><strong>Flexibilidade:</strong> Atualize facilmente a tela de carregamento com novos banners e mensagens conforme necessário.</li>
</ul>
`,
                    img: '/images/news/003.png'
                },
                {
                    type: 'SHORTEN_LINKS', 
                    title: 'Aproveite ao Máximo as Métricas Avançadas',
                    text: `
                        <p>Nosso sistema de encurtamento de URLs oferece recursos avançados de coleta de métricas, permitindo que você obtenha insights detalhados sobre o desempenho dos seus links. Confira os benefícios de utilizar nossas ferramentas de métricas:</p>
                        <ul>
                        <li><strong>Contagem de Acessos:</strong> Monitore quantas vezes seus links foram clicados para avaliar o alcance e a popularidade.</li>
                        <li><strong>Análise Regional:</strong> Identifique de quais regiões geográficas vêm os cliques, ajudando a direcionar suas campanhas de marketing.</li>
                        <li><strong>Dimensões de Tela:</strong> Saiba quais dispositivos seus usuários estão utilizando, permitindo a otimização do conteúdo para diferentes tamanhos de tela.</li>
                        <li><strong>Compartilhamentos em Redes Sociais:</strong> Acompanhe como seus links estão sendo compartilhados nas principais redes sociais (veja o recurso "Compartilhar em").</li>
                        <li><strong>Plataformas de Acesso:</strong> Descubra em quais plataformas (desktop, mobile, tablet) seus links estão sendo acessados para melhorar a experiência do usuário.</li>
                        </ul>
                    `,
                    img: '/images/news/004.png'
                },
                {
                    type: 'SHORTEN_LINKS', 
                    title: 'Personalização Completa de URL com Domínio Próprio',
                    text: `
                        <p>Personalize suas URLs de forma completa, incluindo a possibilidade de usar seu próprio domínio. Aumente a confiança e o reconhecimento da marca ao compartilhar links curtos e personalizados, alinhados com sua identidade visual. Ideal para empresas que buscam uma presença online profissional e coesa. Confira os benefícios:</p>
                        <ul>
                        <li><strong>Identidade de Marca:</strong> Mantenha suas URLs consistentes com a identidade visual da sua marca.</li>
                        <li><strong>Confiança e Credibilidade:</strong> Aumente a confiança dos usuários com URLs profissionais e personalizadas.</li>
                        <li><strong>Domínio Próprio:</strong> Use seu próprio domínio para criar links curtos e personalizados.</li>
                        <li><strong>SEO Aprimorado:</strong> Melhore o SEO com URLs customizadas e otimizadas.</li>
                        <li><strong>Flexibilidade:</strong> Atualize e personalize suas URLs conforme suas necessidades.</li>
                        <li><strong>Engajamento:</strong> Atraia mais cliques e engajamento com URLs curtas e memoráveis.</li>
                        </ul>
                        `,
                    img: '/images/news/008.jpeg'
                },
                {
                    type: 'CHROME_EXTENSION', 
                    title: 'Encurtador de Links Automático para Google Chrome',
                    text: `
                        <p>Com a extensão Migly para Google Chrome, você pode encurtar links automaticamente e associá-los à sua conta Migly. Aproveite essa ferramenta poderosa gratuitamente e simplifique a maneira como você compartilha URLs.</p>
                        <h2>Benefícios da Extensão:</h2>
                        <ul>
                        <li><strong>Encurtamento Automático de Links:</strong> Simplifique e encurte URLs diretamente do seu navegador sem esforço manual.</li>
                        <li><strong>Associação com Conta Migly:</strong> Todos os links encurtados são automaticamente vinculados à sua conta Migly para fácil gestão e acompanhamento.</li>
                        <li><strong>Gratuita:</strong> Desfrute de todas essas funcionalidades sem custo algum.</li>
                        <li><strong>Coleta de Métricas:</strong> Acompanhe os acessos, regiões e plataformas diretamente pela sua conta Migly.</li>
                        <li><strong>Customização de URLs:</strong> Personalize a previsualização dos links com imagens e descrições específicas para redes sociais.</li>
                        <li><strong>Facilidade de Uso:</strong> Interface intuitiva e fácil de usar, que agiliza seu trabalho diário.</li>
                        <li><strong>Segurança e Confiabilidade:</strong> Utilize uma plataforma segura e confiável para encurtar e compartilhar seus links.</li>
                        </ul>
                        <p>Baixe agora na <a href="https://chromewebstore.google.com/detail/miglyin/mbadgimimpdakeglbfkadhpjphbghalb">Chrome Web Store</a>.</p>
                    `,
                    img: '/images/news/009.png'
                },
            ],
            otherNews: [
                {
                    title: 'API para desenvolvedores',
                    text: `Nossa API de encurtamento de URLs oferece uma solução robusta e fácil de integrar para desenvolvedores.`,
                    img: '/images/news/005.png',
                    src: '/api-doc'
                },
                {
                    title: 'Bio migly',
                    text: `Centralize seus links de perfis sociais em um único lugar. Acesse e compartilhe todas as suas redes com facilidade.`,
                    img: '/images/news/006.png',
                    src: '/painel/links/page-bio'
                },
                {
                    title: 'Mais métricas',
                    text: `Rastreie e analise o desempenho dos seus links por localização. Obtenha insights valiosos para otimizar suas campanhas.`,
                    img: '/images/news/007.png',
                    src: '/painel/links/shortened'
                },
            ]
        },
        otherNews: 'Conheça outros recursos',
        accessPainel: 'Acesse o painel',
        plan_BUSINESS: 'Empresarial',
        
    },
    'en': {
        projectDescription : 'Shorten links and urls for free with migly.in',
        shortenToURL: 'Shorten to URL',
        
        shortenAddress: `Shorten address`,
        urlCopiedToClipboard: 'url copied to clipboard',
        copyUrl: 'Copy link',
        viewUrl: 'Visit link',
        toClean: 'To Clean',
        invalidUrl : `Invalid URL`,
        login: `Login`,
        signUp: `Sign Up`,
        anchorLink1: '',
        all: 'Todos',

        plansAndPrices: `Plans and Prices`,
        plansAndPricesDescription: 'Get an essential solution to generate, share and track links for all forms of communication. Choose your plan below.',
        plan_FREE: 'Free',
        plan_BASIC: 'Basic',
        plan_PRO: 'Pro',
        
        
        
    },
};

const i18n = new VueI18n({
    locale: 'pt-br', // set locale
    fallbackLocale: 'en', // set fallback locale
    messages, // set locale messages
});

export default i18n;